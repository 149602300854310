import React from "react";
import { useHistory } from "react-router-dom";
import { TextSmall } from "~/components/Typography";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import MultiSelectAndOther from "~/screens/Home/PageShells/MultiSelectAndOther";

type Props = {
  nextPath?: string;
};

export default function ResistantFeatures({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        Does your home have any features to help mitigate damage from natural
        disasters (hurricanes, windstorms, tornadoes, etc.)?
      </TextSmall>
    ),
  });

  const handleChange = async (values: string[]) => {
    await ihCtx.saveData(
      {
        resistantFeatures: values,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <MultiSelectAndOther
      onChange={handleChange}
      initialValues={ihCtx.data?.resistantFeatures || []}
      text="Does your home have any resistance features?"
      fieldName="resistantFeatures"
      buttonOptions={[
        {
          label: "Shutters",
          value: "shutters",
        },
        {
          label: "Impact Glass (Miami Dade standard)",
          value: "impact-glass",
        },
        {
          label: "Hurricane/Roof Tiles",
          value: "roof-ties",
        },
      ]}
      header={{
        currentStep: 11,
        totalSteps: 24,
        title: "Construction",
      }}
    />
  );
}
