import React from "react";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { InputProps } from "~/components/TextInput/index.types";
import { H4 } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { required } from "~/helpers/validators";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  onChange: (value: string | undefined) => void;
  mainText: string;
  subText?: string | React.ReactNode;
  detailText: string;
  detailPlaceholder?: string;
  fieldName: string;
  fieldType?: InputProps["type"];
  fieldInputMode?: InputProps["inputMode"];
  size?: InputProps["size"];
  initialValue?: string;
  showDetailOnFalse?: boolean;
  header: {
    currentStep: number;
    totalSteps: number;
    title: string;
  };
};

export default function BooleanAndDetails({
  mainText,
  subText,
  detailText,
  detailPlaceholder,
  fieldName,
  fieldType = "text",
  fieldInputMode,
  size,
  showDetailOnFalse,
  initialValue,
  onChange,
  header,
}: Props) {
  const headerCtx = useHeaderContext();
  const [showDetails, setShowDetails] = React.useState(!!initialValue);
  const [saving, setSaving] = React.useState<boolean>(false);
  const detailsFieldName = `detailsField-${fieldName}`;
  const { value, error } = useCustomFieldState(detailsFieldName);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: header.currentStep,
      totalOfSteps: header.totalSteps,
      title: header.title,
    });
  });

  const handleRadioClick = (yes: boolean) => {
    if ((yes && !showDetailOnFalse) || (!yes && showDetailOnFalse)) {
      setShowDetails(true);
    } else {
      setSaving(true);
      onChange(undefined);
    }
  };

  const handleSaveClick = () => {
    setSaving(true);
    onChange(value.toString());
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <fieldset>
          <legend>
            <H4>{mainText}</H4>

            {!!subText && <div>{subText}</div>}
          </legend>

          <RadioBinary handleClick={handleRadioClick} field={fieldName} />

          {showDetails && (
            <>
              <H4>{detailText}</H4>

              <div className={styles.TextInputWrapperThin}>
                <TextInput
                  hideLabelFromView
                  field={detailsFieldName}
                  type={fieldType}
                  keepState
                  placeholder={detailPlaceholder ?? "Details"}
                  validate={required("Please enter details")}
                  validateOnBlur
                  validateOnChange
                  autoFocus
                  initialValue={initialValue ? initialValue : undefined}
                  inputMode={fieldInputMode}
                  size={size}
                />
              </div>

              <div className={styles.ButtonWrapper}>
                <SubmitButton
                  id={`${fieldName}-SubmitButton`}
                  className={styles.ButtonNext}
                  disabled={!!(error || !value || !String(value)?.length)}
                  onClick={handleSaveClick}
                >
                  Next
                </SubmitButton>
              </div>
            </>
          )}
        </fieldset>
      )}
    </>
  );
}
