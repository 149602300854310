import { Option, Select } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3, TextSmall } from "~/components/Typography";
import { useHomeContext } from "~/contexts/HomeProvider";
import { PARISHES } from "~/helpers/constants";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { required } from "~/helpers/validators";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

enum Field {
  HouseNumber = "houseNumberField",
  Street = "streetField",
  District = "districtField",
}

export default function AddressInfo({ nextPath }: Props) {
  const history = useHistory();
  const homeCtx = useHomeContext();

  const [saving, setSaving] = React.useState<boolean>(false);

  const { value: houseNumberValue } = useCustomFieldState(Field.HouseNumber);
  const { value: streetValue } = useCustomFieldState(Field.Street);
  const { value: districtValue } = useCustomFieldState(Field.District);

  const handleNextClick = async () => {
    setSaving(true);

    await homeCtx.setState({
      address: houseNumberValue
        ? `${houseNumberValue.toString()} ${streetValue.toString()}`
        : streetValue.toString(),
      islandHeritageAddressInfo: {
        block: "",
        parcel: "",
        houseNumber: houseNumberValue.toString() ?? "",
        street: streetValue.toString(),
        district: districtValue.toString(),
        island: "Barbados",
        country: "Barbados",
      },
    });

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <fieldset>
          <legend>
            <H3>Please provide some details about your home&apos;s address</H3>
          </legend>

          <div className={styles.TextInputWrapperThin}>
            <TextInput
              field={Field.HouseNumber}
              placeholder="House Number"
              type="text"
              label="House Number (optional)"
              keepState
              initialValue={
                homeCtx.islandHeritageAddressInfo?.houseNumber?.toString() ??
                undefined
              }
            />
          </div>

          <div className={styles.TextInputWrapperThin}>
            <TextInput
              field={Field.Street}
              placeholder="Street"
              type="text"
              label="Street"
              keepState
              validate={required(
                "Please fill out this line (use 'N/A' if not applicable)"
              )}
              validateOnBlur
              validateOnChange
              initialValue={
                homeCtx.islandHeritageAddressInfo?.street ?? undefined
              }
            />
          </div>

          <div className={styles.TextInputWrapperThin}>
            <TextSmall className={styles.InputLabel}>Parish</TextSmall>

            <Select
              field={Field.District}
              initialValue={homeCtx.islandHeritageAddressInfo?.district ?? null}
              style={{ fontSize: "20px", marginBottom: "20px" }}
            >
              <Option value="" disabled>
                Select a parish
              </Option>

              {PARISHES.map((parish) => {
                return (
                  <Option
                    key={parish.label}
                    value={parish.value}
                    field={Field.District}
                  >
                    {parish.label}
                  </Option>
                );
              })}
            </Select>
          </div>

          <div className={styles.ButtonWrapper}>
            <SubmitButton
              id="AddressInfo-SubmitButton"
              className={styles.ButtonNext}
              onClick={handleNextClick}
              disabled={!streetValue || !districtValue}
            >
              Next
            </SubmitButton>
          </div>
        </fieldset>
      )}
    </>
  );
}
