import React from "react";
import { useHistory } from "react-router";
import Button from "~/components/Button";
import SubmitButton from "~/components/SubmitButton";
import Icon from "~/components/Icon";
import Modal from "~/components/Modal";
import SelectableCard from "~/components/SelectableCard";
import TileCard from "~/components/TileCard";
import { Bold, H3, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { IContentsType, useHomeContext } from "~/contexts/HomeProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { formatCurrency } from "~/helpers/currency";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import pushWithParams from "~/helpers/pushWithParams";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import ContentItemModal from "../../../../components/ContentItemModal";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

const CONTENTS_VALUE_PERCENTAGE = 0.2;
const REPLACEMENT_VALUE_PERCENTAGE = 0.05;

export default function ContentsItems({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const homeCtx = useHomeContext();
  const heapCtx = useHeapContext();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = React.useState<number>();
  const [isValid, setIsValid] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 1,
      totalOfSteps: 3,
      title: "Content Details",
    });

    return () => {
      if (openModal) {
        setOpenModal(false);
      }
    };
  });

  useUpdateAssistant({
    isOpen: true,
    text: (
      <TextSmall>
        Providing this list ensures that the full replacement value of these
        items is covered.
      </TextSmall>
    ),
  });

  React.useEffect(() => {
    setIsValid(homeCtx.contents !== undefined && homeCtx.contents.length > 0);
  }, [homeCtx.contents]);

  const handleOpenAddItem = () => {
    setSelectedItemIndex(undefined);
    setOpenModal(true);
  };

  const handleOpenEditItem = (index: number) => {
    setSelectedItemIndex(index);
    setOpenModal(true);
  };

  const handleModalSave = (savedItem: IContentsType) => {
    const contents = homeCtx.contents ?? [];

    if (selectedItemIndex !== undefined && contents[selectedItemIndex]) {
      contents[selectedItemIndex] = savedItem;
    } else {
      contents.push(savedItem);
    }

    homeCtx.setState({
      contents: [...contents],
    });

    heapCtx.track(HeapEventName.HOME_CONTENTS_ITEMS_DETAILS_ADD, {
      Content: savedItem?.name,
      Value: savedItem?.value,
      "Purchase Date": savedItem?.purchaseDate,
    });

    setOpenModal(false);
    setSelectedItemIndex(undefined);
  };

  const handleModalDelete = () => {
    const contents = homeCtx.contents ?? [];

    if (selectedItemIndex !== undefined && contents[selectedItemIndex]) {
      heapCtx.track(HeapEventName.HOME_CONTENTS_ITEMS_DETAILS_REMOVE, {
        Content: contents[selectedItemIndex]?.name,
        Value: contents[selectedItemIndex]?.value,
        "Purchase Date": contents[selectedItemIndex]?.purchaseDate,
      });
      contents.splice(selectedItemIndex, 1);
    }

    homeCtx.setState({
      contents: [...contents],
    });

    setOpenModal(false);
    setSelectedItemIndex(undefined);
  };

  const handleNextClick = () => {
    heapCtx.track(HeapEventName.HOME_CONTENTS_ITEMS, {
      "Number of Contents": Array.isArray(homeCtx.contents)
        ? homeCtx.contents.length
        : 0,
    });
    if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Please list any specific items that meet the following criteria:</H3>

      <ul className={styles.SubTitle}>
        <li>
          <TextSmall>
            Any piece of electronics worth over <Bold>BBD$1000</Bold>
          </TextSmall>
        </li>

        <li>
          <TextSmall>
            Any piece of jewellery or valuable worth over <Bold>BBD$500</Bold>
          </TextSmall>
        </li>

        <li>
          <TextSmall>
            Individual items that exceed{" "}
            <Bold>
              BBD
              {formatCurrency(
                homeCtx.replacementValue
                  ? homeCtx.replacementValue *
                      CONTENTS_VALUE_PERCENTAGE *
                      REPLACEMENT_VALUE_PERCENTAGE
                  : 0,
                true,
                false
              )}
            </Bold>
          </TextSmall>
        </li>
      </ul>

      <TextSmall>
        This step is optional, however any items not listed that exceed the
        above values will be subject to your policy’s maximum coverage amounts
        for individual items.
      </TextSmall>

      <div className={styles.ItemContainer}>
        {homeCtx.contents?.map((item, index) => {
          return !item.isAllRisk ? (
            <div
              className={styles.ItemWrapper}
              key={`ContentsItems-ItemWrapper-${index}`}
            >
              <TileCard
                title={item.name}
                text={formatCurrency(item.value, true)}
                checked
                inputId={`AllRiskItems-TileCard-${index}`}
                inputElement={undefined}
                left={
                  <Icon
                    name="CircleCheck"
                    backgroundColor="#FF6B28"
                    fill="white"
                    className={styles.CircleCheck}
                  />
                }
                right={
                  <Button
                    variant={"tertiary"}
                    size={"small"}
                    onClick={() => {
                      handleOpenEditItem(index);
                    }}
                  >
                    Edit
                  </Button>
                }
              />
            </div>
          ) : undefined;
        })}

        <div className={styles.ItemWrapper}>
          <SelectableCard
            id="ContentsItems-SelectableCard-Add"
            key={`ContentsItems-SelectableCard-Add`}
            className={styles.AddButton}
            title={"Add new item"}
            description={"Tap to add"}
            selected={false}
            onClick={handleOpenAddItem}
          />
        </div>
      </div>

      <Modal
        isOpen={openModal}
        title={selectedItemIndex !== undefined ? "Edit Item" : "Add Item"}
        shouldOverlayCloseOnClick
        onClose={() => setOpenModal(false)}
      >
        <ContentItemModal
          originalContentItem={
            selectedItemIndex !== undefined
              ? homeCtx.contents?.[selectedItemIndex]
              : undefined
          }
          handleSave={handleModalSave}
          handleDelete={handleModalDelete}
          isAllRisk={false}
        />
      </Modal>

      <div className={styles.ButtonWrapper}>
        <SubmitButton id="ContentsItems-NextButton" onClick={handleNextClick}>
          {isValid ? "Next" : "Skip"}
        </SubmitButton>
      </div>
    </div>
  );
}
