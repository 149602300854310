import { format } from "date-fns";
import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import NoteWithList from "~/components/NoteWithList";
import { Bold, H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./style.module.scss";
import { localDateFromIso } from "~/helpers/dates";
import { InsuranceType } from "../../../../../types";

type Props = {
  insuranceType: InsuranceType;
  nextPath?: string;
};

export default function ChecklistStarted({ insuranceType, nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [noteList, setNoteList] = React.useState<string[]>([]);

  useMountEffect(() => {
    // Short circuit this entire page, we don't need to show it for home
    if (insuranceType === "home" && nextPath) {
      history.push(nextPath);
      return;
    }

    insuranceType === "motor"
      ? heapCtx.track(HeapEventName.MOTOR_CHECKLIST_STARTED_SCREEN, {})
      : heapCtx.track(HeapEventName.HOME_CHECKLIST_STARTED_SCREEN, {});
    headerCtx.setState({
      currentStep: 1,
      totalOfSteps: 6,
      title: "Finish Onboarding",
    });
  });

  const policyId = checklistCtx.policyId;
  const dueDate = format(localDateFromIso(checklistCtx.dueDate), "MMM d, yyyy");

  const onContinueClick = () => {
    if (nextPath) {
      insuranceType === "motor"
        ? heapCtx.track(HeapEventName.MOTOR_CHECKLIST_STARTED, {
            "Policy ID": checklistCtx.policyId,
          })
        : heapCtx.track(HeapEventName.HOME_CHECKLIST_STARTED, {
            "Policy ID": checklistCtx.policyId,
          });
      history.push(nextPath);
    }
  };

  React.useEffect(() => {
    if (insuranceType === "motor") {
      const list: string[] = [
        `Your motor purchase letter`,
        `Your motor registration card`,
        `Your vehicle valuation`,
        `Your assignment letter (if your vehicle is financed)`,
        `No claims letter or renewal notice (optional)`,
      ];

      checklistCtx.requiredDocuments.forEach((document) => {
        if (document.documentType === "ROADWORTHY_CERTIFICATE") {
          list.push(`Roadworthy Certificate (for vehicles over 10 years old)`);
        }
      });

      setNoteList(list);
    } else if (insuranceType === "home") {
      const list: string[] = [
        `A recent home valuation of your property from an appraiser`,
      ];

      if (
        checklistCtx.requiredDocuments.some((document) => {
          return document.documentType === "CONTENTS_CHECKLIST";
        })
      ) {
        list.push(`A completed contents checklist`);
      }

      setNoteList(list);
    }
  }, [checklistCtx.requiredDocuments, insuranceType]);

  if (insuranceType === "home") {
    return <></>;
  }

  return (
    <>
      <Text>
        Finish onboarding for <Bold>Policy {policyId}</Bold>
      </Text>

      <H3>Just a quick note before you continue...</H3>

      <NoteWithList
        className={styles.Note}
        list={noteList}
        mainText={
          <Text>
            To issue your full policy certificate we need a bit more
            information.{" "}
            <Bold>Please provide this information by {dueDate}.</Bold> Here’s
            {insuranceType === "motor"
              ? " a few documents"
              : " the document"}{" "}
            you’ll need handy to complete the process:
          </Text>
        }
      />

      <SubmitButton
        id="ChecklistStarted-SubmitButton"
        className={styles.ContinueButton}
        onClick={onContinueClick}
      >
        Ok, got it!
      </SubmitButton>
    </>
  );
}
