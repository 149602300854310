import { matchPath } from "react-router";
import { PolicyObject } from "../../types";
import { Provider } from "../constants";

export type StepComponentPropsType = {
  onSubmit: () => void;
  next: () => void;
  isInLastStep?: boolean;
};

export type StepType = {
  path: string;
  title?: string;
  headerTitle?: string;
  hideBackButton?: boolean;
  nextPathOverride?: string;
};

export type ElementStepType = {
  path: string;
  title?: string;
  headerTitle?: string;
  hideBackButton?: boolean;
  nextPathOverride?: string;
  element: React.ReactElement;
  providerExclusive?: Provider;
  isAuthorized?: boolean;
};

export const calculateCurrentStepIndex = (steps: StepType[], path: string) => {
  const currentStepIndex = steps
    .map((item) => item.path)
    .findIndex((stepPath: string) => {
      const match = matchPath(stepPath, {
        path,
        exact: true,
        strict: true,
      });

      return match && match.isExact;
    });
  return currentStepIndex;
};

export const getNextPath = (steps: StepType[], path: string) => {
  const currentStepIndex = calculateCurrentStepIndex(steps, path);

  if (steps[currentStepIndex]?.nextPathOverride) {
    return steps[currentStepIndex].nextPathOverride;
  }

  if (currentStepIndex === -1 || currentStepIndex + 1 === steps.length) {
    return null;
  }

  return steps[currentStepIndex + 1].path;
};

export const getPreviousPath = (steps: StepType[], path: string) => {
  const currentStepIndex = calculateCurrentStepIndex(steps, path);
  if (currentStepIndex < 1) {
    return null;
  }
  return steps[currentStepIndex - 1].path;
};

export const screenTransitionAnimation = {
  atLeave: {},
  atEnter: { opacity: 0 },
  atActive: { opacity: 1 },
};

export const getPortalChecklistRoute = (
  policyObject: PolicyObject,
  policyId: string,
  endpoint: string
): string => {
  const typeRoute = policyObject === "auto" ? "motor" : "home";
  return `/checklist/${typeRoute}/${policyId}/${endpoint}`;
};
