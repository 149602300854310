import React from "react";
import gql from "graphql-tag";
import { ToolProps } from "../..";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import hasErrorCode from "~/helpers/hasErrorCode";
import styles from "./../AdminUpdatePolicyPremium/index.module.scss";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";

enum Field {
  Passcode = "AdminForceDocumentSync-Passcode",
}

const FORCE_DOCUMENT_SYNC = gql`
  mutation adminForceDocumentSync($policyId: String!, $passcode: String!) {
    adminForceDocumentSync(policyId: $policyId, passcode: $passcode)
  }
`;

const AdminForceDocumentSync = ({ identifier, onClose }: ToolProps) => {
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [forceDocumentSync] = useMutation(FORCE_DOCUMENT_SYNC);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await forceDocumentSync({
        variables: {
          policyId: identifier,
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);

      if (response?.data?.adminForceDocumentSync) {
        onClose({
          manuallyClosed: false,
          success: true,
          shouldReload: false,
          message: "Edge system sync-docs triggered successfully.",
        });
      } else {
        throw new Error("Failed to trigger document sync.");
      }
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <TextInput
        type="text"
        label="Passcode"
        placeholder="Passcode"
        field={Field.Passcode}
      />

      <Button
        disabled={!passcodeState.value || processing}
        onClick={handleSubmit}
      >
        {processing ? "Processing..." : "Process"}
      </Button>
    </div>
  );
};

export default AdminForceDocumentSync;
