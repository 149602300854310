import React from "react";
import { useHistory } from "react-router";
import { H3 } from "~/components/Typography";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { FormValue, RadioGroup } from "informed";
import RadioSelector from "~/components/RadioSelector";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import styles from "./index.module.scss";
import { useUserContext } from "~/contexts/UserProvider";

type Props = {
  nextPath?: string;
};

export default function MaritalStatus({ nextPath }: Props) {
  const history = useHistory();
  const userCtx = useUserContext();
  const heapCtx = useHeapContext();
  const fieldName = "maritalStatus";
  const { value } = useCustomFieldState(fieldName);

  const handleOnNext = (value: FormValue) => {
    if (nextPath) {
      userCtx.setState({
        married: value.toString() === "married",
      });

      heapCtx.track(HeapEventName.HOME_MARITAL_STATUS, {
        "Marital Status": value as string,
      });

      history.push(nextPath);
    }
  };

  return (
    <>
      <H3>What is your Marital Status?</H3>

      <div className={styles.MaritalStatusContent}>
        <RadioGroup field={fieldName} keepState>
          <RadioSelector
            key="single"
            field={fieldName}
            value="single"
            checked={value === "single"}
            onChange={handleOnNext}
          >
            Single
          </RadioSelector>

          <RadioSelector
            key="married"
            field={fieldName}
            value="married"
            checked={value === "married"}
            onChange={handleOnNext}
          >
            Married
          </RadioSelector>
        </RadioGroup>
      </div>
    </>
  );
}
