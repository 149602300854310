import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { required } from "~/helpers/validators";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function FloorArea({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 17,
      totalOfSteps: 24,
      title: "Property Details",
    });
  });

  const fieldName = "approximateFloorArea";
  const { value, error } = useCustomFieldState(fieldName);

  const handleOnSave = async () => {
    await ihCtx.saveData(
      {
        area: Number(value.toString()),
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  useUpdateAssistant({
    text: (
      <TextSmall>
        Give us an estimate of the square footage of your entire home.
      </TextSmall>
    ),
  });

  return (
    <fieldset>
      <legend>
        <H3>What is the approximate floor area of your home?</H3>
      </legend>

      <div className={styles.TextInputWrapper}>
        <TextInput
          hideLabelFromView
          field={fieldName}
          placeholder="2500 ft"
          type="number"
          size={10}
          keepState
          validate={required("Please enter the approximate floor area in feet")}
          validateOnBlur
          validateOnChange
          autoFocus
          initialValue={ihCtx.data?.area?.toString()}
          inputMode="numeric"
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="FloorArea-SubmitButton"
          className={styles.ButtonNext}
          disabled={!!(error || !value || !String(value)?.length)}
          onClick={handleOnSave}
        >
          Next
        </SubmitButton>
      </div>
    </fieldset>
  );
}
