import React from "react";
import { useHistory } from "react-router-dom";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import RadioAndOther from "~/screens/Home/PageShells/RadioAndOther";

type Props = {
  nextPath?: string;
};

export default function CosntructionRoof({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string) => {
    await ihCtx.saveData(
      {
        roof: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <RadioAndOther
      onChange={handleChange}
      text={
        <>
          What is the <u>roof</u> of your home made with?
        </>
      }
      fieldName="constructionRoof"
      buttonOptions={[
        {
          label: "Concrete",
          value: "concrete",
        },
        {
          label: "Shingle",
          value: "shingle",
        },
        {
          label: "Tile",
          value: "tile",
        },
        {
          label: "Wood",
          value: "wood",
        },
        {
          label: "Standing Seam",
          value: "standing-seam",
        },
      ]}
      header={{
        currentStep: 8,
        totalSteps: 24,
        title: "Construction",
      }}
      initialValue={ihCtx.data.roof}
    />
  );
}
