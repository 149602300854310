import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import { H3, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import {
  IslandHeritageJointInsured,
  useIslandHeritageContext,
  getDefaultJointInsurer,
} from "~/contexts/IslandHeritageProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

type Props = {
  nextPath?: string;
};

export default function HasJointInsurer({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 1,
      totalOfSteps: 24,
      title: "Joint Insurer",
    });
  });

  useUpdateAssistant({
    text: (
      <TextSmall>
        Is there a second owner of the home, or someone else who should be
        listed on the insurancy policy?
      </TextSmall>
    ),
  });

  const [saving, setSaving] = React.useState<boolean>(false);

  const handleClick = async (value: boolean) => {
    setSaving(true);
    let path: string = nextPath ?? "";

    let newJointInsuredValue:
      | IslandHeritageJointInsured
      | undefined = undefined;

    if (value) {
      if (ihCtx.data.jointInsured) {
        history.push(path);
        return;
      } else {
        newJointInsuredValue = getDefaultJointInsurer();
      }
    } else {
      newJointInsuredValue = undefined;
      path = `/island-heritage/home/${ihCtx.policyId}/construction-floors`;
    }

    await ihCtx.saveData(
      {
        jointInsured: newJointInsuredValue,
      },
      path
    );

    history.push(path);
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>Is there a joint insurer for your home?</H3>
          <RadioBinary handleClick={handleClick} field="hasJointInsurer" />
        </>
      )}
    </>
  );
}
