import React from "react";
import { useHistory } from "react-router-dom";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import RadioAndOther from "~/screens/Home/PageShells/RadioAndOther";

type Props = {
  nextPath?: string;
};

export default function PropertyDescription({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string) => {
    await ihCtx.saveData(
      {
        propertyType: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <RadioAndOther
      onChange={handleChange}
      text="How would you describe your home?"
      fieldName="propertyDescription"
      buttonOptions={[
        {
          label: "Detached Home",
          value: "detached",
        },
        {
          label: "Semi-Detached or Duplex",
          value: "semi-detached",
        },
        {
          label: "Townhouse",
          value: "townhouse",
        },
        {
          label: "Apartment or Condominium",
          value: "condo",
        },
      ]}
      header={{
        currentStep: 14,
        totalSteps: 24,
        title: "Property Details",
      }}
      initialValue={ihCtx.data?.propertyType}
    />
  );
}
