import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3, H5, H6, TextSmall } from "~/components/Typography";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function IHBuildings({ nextPath }: Props) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "islandHeritageBuildings";
  const { value, error } = useCustomFieldState(fieldName);

  const handleOnSave = () => {
    console.log("Buildings Value: ", value);

    homeCtx.setState({
      islandHeritageBuildings: value ? cleanNumberInput(value) : undefined,
    });

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3>Buildings</H3>

        <H5>
          Does your home have any of the following that you would like covered?
        </H5>

        <H6>If yes, please insert the total value below</H6>

        <TextSmall>
          <br />

          <ul className={styles.ItemList}>
            <li>Landlord&apos;s Fixtures and Fittings</li>
            <li>Patios</li>
            <li>Terraces</li>
            <li>Swimming Pools</li>
            <li>Footpaths</li>
            <li>Satellite Dishes</li>
            <li>External Antennae</li>
            <li>Hard Courts</li>
            <li>Walls</li>
            <li>Gates</li>
            <li>Fences</li>
          </ul>
        </TextSmall>
      </legend>

      <div className={styles.TextInputWrapperThin}>
        <TextInput
          hideLabelFromView
          field={fieldName}
          placeholder="$ 0"
          type="currency"
          size={10}
          keepState
          autoFocus
          inputMode="numeric"
          initialValue={homeCtx.islandHeritageBuildings}
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="Buildings-SubmitButton"
          className={styles.ButtonNext}
          disabled={!!error}
          onClick={handleOnSave}
        >
          {value ? "Next" : "Skip"}
        </SubmitButton>
      </div>
    </fieldset>
  );
}
