import { FormValue, useFieldState } from "informed";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import SearchList from "~/components/SearchList";
import { Bold, H3, Text } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useMotorContext } from "~/contexts/MotorProvider";
import pushWithParams from "~/helpers/pushWithParams";
import { InsuranceType } from "../../types";
import { gql } from "apollo-boost";

import styles from "./style.module.scss";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";

const UPDATE_MOTOR_OCCUPATION = gql`
  mutation updateMotorOccupation($policyId: String!, $occupation: String!) {
    updateMotorOccupation(policyId: $policyId, occupation: $occupation)
  }
`;

const UPDATE_HOME_OCCUPATION = gql`
  mutation updateHomeOccupation($policyId: String!, $occupation: String!) {
    updateHomeOccupation(policyId: $policyId, occupation: $occupation)
  }
`;

enum Field {
  Other = "Occupation-OtherField",
}

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const Occupation = ({ nextPath, insuranceType }: Props) => {
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const history = useHistory();
  const heapCtx = useHeapContext();
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [showOtherInput, setShowOtherInput] = React.useState<boolean>(false);
  const otherFieldState = useFieldState(Field.Other);

  const [updateOccupation] = useMutation(
    insuranceType === "motor" ? UPDATE_MOTOR_OCCUPATION : UPDATE_HOME_OCCUPATION
  );

  const occupations = [
    "Other",
    ...(insuranceType === "home" ? ["Student"] : []),
    "Attorney-at-Law",
    "Accountant",
    "Administrative Assistant",
    "Architect",
    "Banker",
    "Bartender",
    "Chief Executive Officer",
    "Civil Servant",
    "Clergy",
    "Consultant",
    "Dentist",
    "Driver",
    "Economist",
    "Educator",
    "Engineer",
    "Entertainer or DJ",
    "Insurance Executive",
    "Journalist",
    "Librarian",
    "Management and Business Consultant",
    "Manager",
    "Medical Practitioner",
    "Nurse",
    "Pilot",
    "Police Officer",
    "Professor",
    "Psychologist",
    "QEH Staff",
    "Retired",
    "Salesperson",
    "Self Employed",
    "Senior Manager",
    "Social Worker",
    "Soldier",
    "Systems Administrator",
    "Teacher",
    "University Lecturer",
    "Veterinarian",
    "Waiter or Waitress",
    "Unemployed",
  ];

  const handleChange = async (value: FormValue, skipOtherCheck?: boolean) => {
    if (!skipOtherCheck && value === "Other") {
      setShowOtherInput(true);
      return;
    }

    if (insuranceType === "motor") {
      await motorCtx.savingsInfo.setItem("occupation", value as string);
    } else {
      await homeCtx.setState({ occupation: value as string });
    }

    heapCtx.addUserProperties({
      Occupation: value as string,
    });

    heapCtx.track(
      insuranceType === "motor"
        ? HeapEventName.MOTOR_OCCUPATION
        : HeapEventName.HOME_OCCUPATION,
      {
        Occupation: value as string,
      }
    );

    setShowLoading(true);

    try {
      await updateOccupation({
        variables: {
          policyId:
            insuranceType === "motor"
              ? motorCtx.policyInfo.data.id
              : homeCtx.policyId,
          occupation: value as string,
        },
      });
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      throw error;
    }

    if (insuranceType === "home") {
      pushWithParams(
        history,
        `/island-heritage/home/${homeCtx.policyId}/redirect`
      );
    } else if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  if (showLoading) {
    return (
      <div className={styles.LoadingWrapper}>
        <LoadingWrapper loading={showLoading} />
      </div>
    );
  }

  if (showOtherInput) {
    return (
      <fieldset>
        <legend>
          {insuranceType === "motor" && (
            <VehicleSearchDisplaySmall
              prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()}`}
            />
          )}

          <H3 fontWeight="bold" component="h1">
            What is your occupation?
          </H3>
        </legend>

        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          <TextInput
            field={Field.Other}
            placeholder="Occupation"
            type="text"
            autoFocus
          />
        </div>

        <div className={styles.ButtonWrapper}>
          <Button
            variant="tertiary"
            onClick={() => {
              setShowOtherInput(false);
            }}
          >
            Cancel
          </Button>

          <div style={{ display: "inline", marginLeft: "15px" }}>
            <Button
              disabled={!otherFieldState.value}
              onClick={() => {
                handleChange(otherFieldState.value, true);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </fieldset>
    );
  }

  return (
    <fieldset>
      <legend>
        {insuranceType === "motor" && (
          <VehicleSearchDisplaySmall
            prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()}`}
          />
        )}

        <H3 fontWeight="bold" component="h1">
          What is your occupation? Choose the best match.
        </H3>

        <Text className={styles.SubHeader}>
          If you can&apos;t find your occupation, please choose&nbsp;
          <Bold>&apos;Other&apos;</Bold>
        </Text>
      </legend>

      <SearchList
        field="occupation"
        list={occupations}
        onChange={handleChange}
        keepState
        classNameGrid={styles.GridList}
        excludeFromFilter={["Other"]}
        noResultsCopy="Try to type something new, or select Other if you cannot find
        your occupation."
      />
    </fieldset>
  );
};

export default Occupation;
