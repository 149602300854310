import React from "react";
import { useHistory } from "react-router";
import { H5 } from "~/components/Typography";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import BooleanAndDetails from "~/screens/Home/PageShells/BooleanAndDetails";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function IHDIssues({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string | undefined) => {
    await ihCtx.saveData(
      {
        issues: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <BooleanAndDetails
      onChange={handleChange}
      mainText="In the last 5 years, have you in respect of the property insured
        here or anywhere else, had any of the following occured?"
      subText={
        <H5>
          <br />

          <ul className={styles.ItemList}>
            <li>Had Insurance Declined</li>
            <li>Been Required To Pay An Increased Premium</li>
            <li>Had Special Terms Imposed</li>
            <li>Had Insurance Cancelled</li>
            <li>Had Renewal Refused</li>
          </ul>
        </H5>
      }
      detailText="Please provide full details below"
      fieldName="ihdIssues"
      initialValue={ihCtx.data?.issues}
      header={{
        currentStep: 18,
        totalSteps: 24,
        title: "Property Details",
      }}
    />
  );
}
