export const PAYMENT_RESULT_STATUS = {
  success: "success",
  failure: "failure",
};

export const NUMBER_STRING = {
  "12": "twelve",
};

export const CONTRACT_STATUS = {
  completed: "completed",
};

export const FILE_TYPES: {
  [key: string]: { accept: string; extension: string };
} = {
  jpg: {
    accept: "image/jpg",
    extension: ".jpg",
  },
  jpeg: {
    accept: "image/jpeg",
    extension: ".jpeg",
  },
  png: {
    accept: "image/png",
    extension: ".png",
  },
  pdf: {
    accept: "application/pdf",
    extension: ".pdf",
  },
};

export const DOCUMENT_TYPES = {
  //Verifiable Documents
  driversLicense: "DRIVERS_LICENSE",
  idCard: "ID_CARD",
  passport: "PASSPORT",
  face: "FACE",
  //Non-verifiable Documents
  licenseSlip: "LICENSE_SLIP",
  //Checklist Documents
  purchaseLetter: "PURCHASE_LETTER",
  registrationCard: "REGISTRATION_CARD",
  assignmentLetter: "ASSIGNMENT_LETTER",
  valuationCertificate: "VALUATION_CERTIFICATE",
  noClaimsDiscountLetter: "NO_CLAIMS_DISCOUNT_LETTER",
  letterOfSettlement: "LETTER_OF_SETTLEMENT",
  proofAddress: "PROOF_ADDRESS",
  roadworthyCertificate: "ROADWORTHY_CERTIFICATE",
};

export const DOCUMENTS_NAMES = {
  DRIVERS_LICENSE: "Driver License",
  ID_CARD: "National ID",
  LICENSE_SLIP: "Driver License Slip",
  PASSPORT: "Passport",
  FACE: "Selfie",
};

export const VERIFIABLE_DOCUMENTS_TYPES = [
  DOCUMENT_TYPES.passport,
  DOCUMENT_TYPES.idCard,
  DOCUMENT_TYPES.face,
];

export const VERIFICATION_STATUS = {
  started: "started",
  approved: "approved",
  resubmissionRequested: "resubmission_requested",
  timeout: "timeout",
  declined: "declined",
  expired: "expired",
  abandoned: "abandoned",
};

export const POLICY_QUOTE_STATUS = {
  quote: "quote",
  pendingEmailVerification: "pending_email_verification",
  pendingEngineChassisNum: "pending_engchasnum",
  pendingOccupation: "pending_occupation",
  pendingHomeData: "pending_home_data",
  pendingDocuments: "pending_documents",
  pendingSigning: "pending_signing",
  pendingPayment: "pending_payment",
  approvalOnHold: "approval_On_hold",
};

export const POLICY_COMPLETE_STATUS = {
  pendingSecondaryDocuments: "pending_secondary_documents",
  pendingNewPayment: "pending_new_payment",
  pendingApproval: "pending_approval",
  active: "active",
  cancelled: "cancelled",
  expiringSoon: "expiring_soon",
  expired: "expired",
};

export const POLICY_STATUS = {
  ...POLICY_QUOTE_STATUS,
  ...POLICY_COMPLETE_STATUS,
};

export const PAYMENT_STATUS = {
  registered: "registered",
  approved: "approved",
  declined: "declined",
  error: "error",
  failedRecurrence: "failed_recurrence",
  paymentCancelledPolicyPendingNewPayment:
    "payment_canceled_policy_pending_new_payment",
  paymentCancelledPolicySoonToBeCancelled:
    "payment_canceled_policy_soon_to_be_cancelled",
  cancelledPolicy: "cancelled_policy",
  cancelled: "cancelled",
  completed: "completed",
  noPaymentFound: "no_payment_found",
};

export const UPDATABLE_PAYMENT_STATUS = [
  POLICY_COMPLETE_STATUS.pendingApproval,
  POLICY_COMPLETE_STATUS.active,
  POLICY_COMPLETE_STATUS.expiringSoon,
  POLICY_COMPLETE_STATUS.pendingSecondaryDocuments,
  POLICY_COMPLETE_STATUS.pendingNewPayment,
];

export const LOCAL_PHONE_CODES = ["BRB", "GUY"];

export const CARIB_PHONE_CODES = [
  "AIA",
  "BHS",
  "ABW",
  "CUW",
  "SXM",
  "MAF",
  "BLM",
  "DOM",
  "HTI",
  "PRI",
  "VGB",
  "VIR",
  "CYM",
  "TCA",
  "CUB",
  "DMA",
  "JAM",
  "TTO",
  "KNA",
  "LCA",
  "VCT",
];

export const PARISHES = [
  {
    label: "Christ Church",
    value: "CHRIST CHURCH",
  },
  {
    label: "St. James",
    value: "ST. JAMES",
  },
  {
    label: "St. Lucy",
    value: "ST. LUCY",
  },
  {
    label: "St. Michael",
    value: "ST. MICHAEL",
  },
  {
    label: "St. Peter",
    value: "ST. PETER",
  },
  {
    label: "St. Thomas",
    value: "ST. THOMAS",
  },
  {
    label: "St. Andrew",
    value: "ST. ANDREW",
  },
  {
    label: "St. George",
    value: "ST. GEORGE",
  },
  {
    label: "St. John",
    value: "ST. JOHN",
  },
  {
    label: "St. Joseph",
    value: "ST. JOSEPH",
  },
  {
    label: "St. Philip",
    value: "ST. PHILIP",
  },
];

export const ENVIRONMENTS = {
  local: "local",
  development: "development",
  staging: "staging",
  production: "production",
  uat: "uat",
};

export const NCD_HISTORY_CAP = 3;

export const MINIMUM_HOME_REPLACEMENT_VALUE = 100000; // one hundred thousand
export const MAXIMUM_HOME_REPLACEMENT_VALUE = 2500000; // two million five hundred thousand

export const HOME_PROPERTY_EXCESS = 1500;

export const HOME_CONTENTS_EXCESS = 500;

export const HOME_ALL_RISK_EXCESS = 500;

export const HOME_TOTAL_EXCESS =
  HOME_PROPERTY_EXCESS + HOME_CONTENTS_EXCESS + HOME_ALL_RISK_EXCESS;

export const HOME_SUM_INSURED = 150000; // one hundred fifty thousand

export const HOME_PUBLIC_LIABILITY = 500000; // five hundred thousand

export const HOME_PERSONAL_LIABILITY = 250000; // two fifty thousand

export const HOME_EMPLOYER_LIABILITY = 2500000; // two point five million

export const DEFAULT_ALL_RISK_TYPE = "local";

export const ENABLE_HOME_FLOW =
  process.env.REACT_APP_ENV !== ENVIRONMENTS.production; // process.env.REACT_APP_HOME_FLOW === "true";

export enum PolicyType {
  Motor = "motor",
  Home = "home",
}

export enum Provider {
  Guardian = "guardian",
  IslandHeritage = "islandHeritage",
}
