import React from "react";
import { useHistory } from "react-router";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";

type Props = {
  nextPath?: string;
};

export default function DataCollectionRedirect({ nextPath }: Props) {
  const ihCtx = useIslandHeritageContext();
  const history = useHistory();

  React.useEffect(() => {
    if (ihCtx.nextPath) {
      history.push(ihCtx.nextPath);
    } else if (nextPath) {
      history.push(nextPath);
    } else {
      history.push("/portal"); //TODO point to 404 page?
    }
  }, [ihCtx.nextPath, history, nextPath]);

  return <div></div>;
}
