import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import * as storage from "~/helpers/storage";

export type IUserData = {
  name?: string;
  email?: string;
  phoneNumber?: string;
  countryPhoneCode?: string;
  diallingCode?: string;
  termsAndConditions?: boolean;
  married?: boolean;
  nationality?: string;
  honorific?: string;
};

export type IUserContext = {
  setState: (newState?: IUserData) => void;
  removeStore: () => void;
} & IUserData;

const keyStore = "userInfo";

const initialState = () => {
  let savedValue = storage.getItem(keyStore) as IUserData;
  savedValue = !savedValue ? {} : savedValue;

  return {
    name: savedValue?.name,
    email: savedValue?.email,
    phoneNumber: savedValue?.phoneNumber,
    countryPhoneCode: savedValue?.countryPhoneCode,
    termsAndConditions: savedValue?.termsAndConditions,
    married: savedValue?.married,
    nationality: savedValue?.nationality,
    honorific: savedValue?.honorific,
    setState() {
      throw new Error("UserProvider context has not yet been initialized.");
    },
    removeStore() {
      throw new Error("UserProvider context has not yet been initialized.");
    },
  };
};

export const UserContext = createContext(initialState() as IUserContext);
export const useUserContext = () => useContext(UserContext);

type Props = {
  children: ReactNode;
};

export default function UserProvider({ children }: Props) {
  const [state, setState] = useState(initialState() as IUserContext);

  const handleNewState = (newValue?: IUserData) => {
    if (newValue) {
      const newState = { ...state, ...newValue };
      const oldState = storage.getItem(keyStore);
      if (!(JSON.stringify(oldState) === JSON.stringify(newState))) {
        storage.saveItem(keyStore, newState);
        setState(newState);
      }
    }
  };

  const handleRemoveStore = () => {
    storage.removeItem(keyStore);
  };

  useEffect(() => {
    setState(initialState() as IUserContext);
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...state,
        setState: handleNewState,
        removeStore: handleRemoveStore,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
