import React from "react";
import gql from "graphql-tag";
import jwtDecode from "jwt-decode";
import { useApolloClient } from "@apollo/react-hooks";
import { useHistory, matchPath } from "react-router-dom";

import { useAuth0Context } from "~/contexts/Auth0Context";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { useUserContext } from "~/contexts/UserProvider";
import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";
import { useUpdateSupport } from "~/helpers/useUpdateSupport";

import Button from "~/components/Button";
import HomePlanDetail from "~/components/PlanDetail/HomePlanDetail";
import LoadingWrapper from "~/components/LoadingWrapper";
import PolicySummaryCard from "~/components/PolicySummaryCard";
import { TextSmall, Bold, TextExtraSmall, H3 } from "~/components/Typography";

import formHomePolicyPayload from "~/helpers/formHomePolicyPayload";
import pushWithParams from "~/helpers/pushWithParams";
import useHasRequiredData from "~/helpers/useHasRequiredData";
import {
  DEFAULT_ALL_RISK_TYPE,
  POLICY_STATUS,
  Provider,
} from "~/helpers/constants";
import { useQueryThrowError } from "~/helpers/queryWithErrorHandling";
import img from "~/assets/images/island-heritage-logo.png";

import {
  CountryCode,
  InsuranceType,
  OnboardingPathMatchType,
  PolicyStatus,
  Quote,
} from "../../../../types";

import styles from "./index.module.scss";
// import { formatCurrency } from "~/helpers/currency";

export type PathMatchType = {
  params: { countryCode: CountryCode; insuranceType: InsuranceType };
};

import { SAVE_HOME_POLICY } from "~/helpers/commonGql";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { GONE_TOO_FAR } from "~/routes/index.constant";
import CoveredList from "./CoveredList";
import { TypeOfCover } from "~/../../api/src/constants";

/*
const TextWithCheck = ({ children }: { children: React.ReactNode }) => (
  <TextSmall className={styles.ListItem}>
    <Icon
      name="CheckRound"
      backgroundColor="transparent"
      fill={styles.chateauGreen}
    />
    {children}
  </TextSmall>
);
*/

const NUMBER_OF_PAYMENTS = {
  twelve: 12,
  one: 1,
};

const HOME_QUOTES = gql`
  query($proposal: HomePolicyProposalInput!) {
    homeQuotes(proposal: $proposal) {
      typeOfCover
      basePremiumValue
      netPremiumValue
      taxValue
      totalValue
      valueByNumberOfInstallments {
        twelve
      }
    }
  }
`;

const getProviderForTypeOfCover = (typeOfCover?: TypeOfCover): Provider => {
  switch (typeOfCover) {
    case "IH_PLUS":
    case "IH_STANDARD":
      return Provider.IslandHeritage;
    default:
      return Provider.Guardian;
  }
};

const CoveragePlans = ({
  nextPath,
  countryName,
}: {
  nextPath?: string;
  countryName: string;
}) => {
  const onboardCtx = useOnboardContext();
  const userCtx = useUserContext();
  const homeCtx = useHomeContext();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const auth0 = useAuth0Context();
  const heapCtx = useHeapContext();
  const [, setError] = React.useState();
  const [showDetail, setShowDetail] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [isValidProposal, setIsValidProposal] = React.useState(true);
  const [selectedQuote, setSelectedQuote] = React.useState<Quote>();
  const [currentDetailIndex, setCurrentDetailIndex] = React.useState<number>(0);

  useUpdateSupport({ isOpen: true });

  useHasRequiredData({
    requiredData: [],
    policyRequiredStatus: POLICY_STATUS.quote as PolicyStatus,
    policyStatus: homeCtx.status,
    skip: complete,
  });

  const allRiskValue =
    homeCtx.contents
      ?.filter((insuredItem) => insuredItem.isAllRisk)
      .map((insuredItem) => insuredItem.value)
      .reduce((total, itemValue) => total + itemValue, 0) ?? 0;

  const contentsValue = homeCtx.contentsValue
    ? homeCtx.contentsValue - allRiskValue
    : 0;

  const proposal = {
    allRiskType: homeCtx.allRiskType ?? DEFAULT_ALL_RISK_TYPE,
    allRiskValue,
    branch: "BB",
    constructionType: homeCtx.constructionType,
    contentsValue,
    coverageStart: homeCtx.coverageStart,
    homeValue: homeCtx.replacementValue,
    isCoastal: homeCtx.islandHeritageLocation === "seaside",
    isExistingMotorCustomer: homeCtx.hasExistingMotorPolicy,
    isShortTermRental: homeCtx.islandHeritageOccupiedBy === "short-rental",
    islandHeritageLocation: homeCtx.islandHeritageLocation,
    islandHeritageOccupiedBy: homeCtx.islandHeritageOccupiedBy,
    islandHeritageAppliances: contentsValue
      ? homeCtx.islandHeritageAppliances
      : false,
  };

  useMountEffect(() => {
    for (const prop in proposal) {
      if (proposal[prop] === undefined) {
        setIsValidProposal(false);
        pushWithParams(history, `${GONE_TOO_FAR}?message=missing-values`);
      }
    }
  });

  const { loading, data } = useQueryThrowError(HOME_QUOTES, {
    variables: {
      emailQuoteInput: {
        email: userCtx.email,
        policyId: homeCtx.policyId,
      },
      skip: !isValidProposal,
      proposal,
    },
  });

  const islandHeritageStandardQuote =
    data &&
    data.homeQuotes.find((quote: Quote) => quote.typeOfCover === "IH_STANDARD");

  const islandHeritagePlusQuote =
    data &&
    data.homeQuotes.find((quote: Quote) => quote.typeOfCover === "IH_PLUS");

  const quotes: Quote[] = [
    islandHeritageStandardQuote,
    islandHeritagePlusQuote,
  ];

  // `quote` is only undefined when data is undefined, i.e. when loading === true
  if (loading || showLoading) {
    return <LoadingWrapper loading={loading} />;
  }

  const isRecurring = onboardCtx.paymentIsRecurring; // i.e. is it monthly?
  const installments = onboardCtx.paymentNumberOfRecurrences ?? 1; // 12 or 1
  const additionalContents = !!homeCtx.contents; // Items in the home are being insured
  const allRisk = homeCtx.allRisk;
  /*
  const subTitle = `Property ${
    additionalContents ? "+ Contents " : ""
  }insurance`;
  */

  const handleHeapEvent = () => {
    heapCtx.track(HeapEventName.HOME_COVERAGE_PLAN, {
      "Policy ID": homeCtx.policyId,
      "Plan Type": homeCtx.typeOfCover,
      "Payment Type": onboardCtx.paymentIsRecurring ? "Recurring" : "Lump Sum",
    });
  };

  const handleOnSelectedPayment = (isRecurring: boolean) => {
    onboardCtx.setState({
      paymentIsRecurring: isRecurring,
      // TODO: we need to better handle the number of payments
      paymentNumberOfRecurrences: isRecurring
        ? NUMBER_OF_PAYMENTS.twelve
        : NUMBER_OF_PAYMENTS.one,
    });
  };

  const pathMatch: OnboardingPathMatchType | null = matchPath(
    history.location.pathname,
    {
      path: "/:countryCode/:insuranceType/:step",
      exact: true,
      strict: false,
    }
  );

  const handleOnDetails = (quote: Quote, index: number) => {
    setSelectedQuote(quote);
    setShowDetail(true);
    setCurrentDetailIndex(index);
  };

  const handleOnNext = () => {
    const newIndex = currentDetailIndex + 1;
    setCurrentDetailIndex(newIndex);
    const newQuote = quotes[newIndex];
    setSelectedQuote(newQuote);
  };

  const handleOnPrev = () => {
    const newIndex = currentDetailIndex - 1;
    setCurrentDetailIndex(newIndex);
    const newQuote = quotes[newIndex];
    setSelectedQuote(newQuote);
  };

  const handleOnSelectPlan = async () => {
    setShowLoading(true);
    setShowDetail(false);

    homeCtx.setState({
      typeOfCover: selectedQuote?.typeOfCover,
      provider: getProviderForTypeOfCover(selectedQuote?.typeOfCover),
    });
    // setState() does not update the state until a state reset is completed
    // formHomePolicyPayload() will use the previous state without this
    homeCtx.setState();

    if (pathMatch) {
      const policyPayload = formHomePolicyPayload(
        homeCtx,
        userCtx.email,
        !!isRecurring,
        pathMatch.params.countryCode,
        false
      );
      try {
        const policyMutation = await apolloClient.mutate({
          mutation: SAVE_HOME_POLICY,
          variables: {
            saveHomePolicyInput: {
              policy: policyPayload,
            },
          },
        });

        if (policyMutation.data.saveHomePolicy) {
          if (!auth0.isLoading()) {
            if (auth0.token) {
              const tokenObj: { email: string } = jwtDecode(
                auth0.token.idToken
              );

              if (userCtx.email === tokenObj.email) {
                if (pathMatch) {
                  setComplete(true);
                  handleHeapEvent();

                  homeCtx.setState({
                    policyId: policyMutation.data.saveHomePolicy,
                    status: POLICY_STATUS.pendingOccupation as PolicyStatus,
                  });

                  pushWithParams(
                    history,
                    `/${pathMatch.params.countryCode}/${pathMatch.params.insuranceType}/Occupation`
                  );

                  return;
                }
              }
            } else {
              setComplete(true);

              homeCtx.setState({
                policyId: policyMutation.data.saveHomePolicy,
                status: POLICY_STATUS.pendingOccupation as PolicyStatus,
              });
            }
          }

          if (nextPath) {
            handleHeapEvent();
            history.push(nextPath);
          }
        }
      } catch (error) {
        // Make error boundary catch this
        setError(() => {
          throw error;
        });

        setShowLoading(false);
      }

      if (nextPath) {
        history.push(nextPath);
      }
    }
  };

  return (
    <>
      <div className={styles.CoveragePlansContent}>
        <div className={styles.HeadingScreen}>
          <TextSmall component="h1">Here is the plan for your</TextSmall>
          <H3 className={styles.HomeCoverage} component="h2">
            Home Coverage
          </H3>
        </div>

        <div
          className={styles.SelectorButtons}
          role="tablist"
          aria-label="Coverage Plans"
        >
          <Button
            id="CoveragePlan-InstalmentsButton"
            role="tab"
            aria-selected={isRecurring}
            aria-controls="instalment-content"
            className={styles.ButtonPayment}
            variant={isRecurring ? "secondary" : "tertiary"}
            size="small"
            onClick={() => handleOnSelectedPayment(true)}
          >
            Instalments
          </Button>

          <Button
            id="CoveragePlan-OnePaymentButton"
            role="tab"
            aria-selected={!isRecurring}
            aria-controls="annual-content"
            className={styles.ButtonPayment}
            variant={!isRecurring ? "secondary" : "tertiary"}
            size="small"
            onClick={() => handleOnSelectedPayment(false)}
          >
            One payment
          </Button>
        </div>

        {onboardCtx.paymentIsRecurring && (
          <div className={styles.RecurringInfo}>
            <TextSmall component="h1">
              12-month installments are 0% interest. We will collect your first
              and last month’s payment today.
              <Bold> Two payments will be taken up-front.</Bold>
            </TextSmall>
          </div>
        )}

        <div
          className={styles.CoveragePlanCards}
          id={
            onboardCtx.paymentIsRecurring
              ? "instalment-content"
              : "annual-content"
          }
        >
          {islandHeritageStandardQuote && (
            <PolicySummaryCard
              title="Island Heritage Standard"
              subTitle="Standard Coverage"
              showPrefix
              installmentCount={onboardCtx.paymentNumberOfRecurrences || 1}
              installmentValue={
                onboardCtx.paymentIsRecurring
                  ? islandHeritageStandardQuote.valueByNumberOfInstallments
                      .twelve
                  : islandHeritageStandardQuote.totalValue
              }
              columns={2}
              onClickDetails={() =>
                handleOnDetails(islandHeritageStandardQuote, 1)
              }
            >
              <CoveredList quote={islandHeritageStandardQuote} />
            </PolicySummaryCard>
          )}

          {islandHeritagePlusQuote && (
            <PolicySummaryCard
              title="Heritage Plus Upgrade"
              subTitle="Additional Coverage"
              variant="highlight"
              showPrefix
              installmentCount={onboardCtx.paymentNumberOfRecurrences || 1}
              installmentValue={
                onboardCtx.paymentIsRecurring
                  ? islandHeritagePlusQuote.valueByNumberOfInstallments.twelve
                  : islandHeritagePlusQuote.totalValue
              }
              columns={2}
              // eslint-disable-next-line no-magic-numbers
              onClickDetails={() => handleOnDetails(islandHeritagePlusQuote, 2)}
            >
              <CoveredList quote={islandHeritagePlusQuote} />
            </PolicySummaryCard>
          )}
        </div>

        {/**
        <div
          className={styles.CoveragePlanCard}
          id={isRecurring ? "instalment-content" : "annual-content"}
        >
          <div className={styles.CoveragePlanContent}>
            <PolicySummaryCard
              variant="highlight"
              showTopHeader={false}
              showBorder={false}
              title="Home Coverage Plan"
              subTitle={subTitle}
              labelReviewDetail="See details and purchase"
              installmentCount={isRecurring ? installments : 1}
              installmentValue={installmentValue}
              onClickDetails={() => setShowDetail(true)}
            >
              <TextWithCheck>
                {formatCurrency(HOME_SUM_INSURED, true)} Sum Insured
              </TextWithCheck>

              <TextWithCheck>
                Up to {formatCurrency(HOME_TOTAL_EXCESS, true)} Excess
              </TextWithCheck>

              <TextWithCheck>Emergency Expenses</TextWithCheck>
              <TextWithCheck>Alternative Accommodations</TextWithCheck>
            </PolicySummaryCard>
          </div>
        </div>
        */}

        <div className={styles.Statement}>
          <TextExtraSmall component="h1">
            Policies are underwritten by Island Heritage Insurance.
          </TextExtraSmall>
        </div>

        <img src={img} className={styles.Logo} alt="Island Heritage Logo" />
      </div>

      {selectedQuote && (
        <HomePlanDetail
          quote={selectedQuote}
          allRisk={allRisk}
          allRiskType={homeCtx.allRiskType}
          allRiskValue={proposal.allRiskValue}
          installments={installments}
          additionalContents={additionalContents}
          countryName={countryName}
          isRecurring={isRecurring}
          replacementValue={homeCtx.replacementValue}
          contentsValue={homeCtx.contentsValue}
          isOpen={showDetail}
          onCloseModal={() => setShowDetail(false)}
          onSelectPlan={handleOnSelectPlan}
          onPrev={
            quotes.length > 1 && currentDetailIndex > 0
              ? handleOnPrev
              : undefined
          }
          onNext={
            quotes.length > 1 && currentDetailIndex < quotes.length - 1
              ? handleOnNext
              : undefined
          }
        />
      )}
    </>
  );
};

export default CoveragePlans;
