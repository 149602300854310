import { useFormState } from "informed";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import TextInput from "../TextInput";
import { useMutation } from "@apollo/react-hooks";
import { isFullName, phoneRequired, validateEmail } from "~/helpers/validators";
import { useUserContext } from "~/contexts/UserProvider";
import React, { SetStateAction, useMemo } from "react";
import { CountryCode, InsuranceType } from "../../types";
import { matchPath, useHistory } from "react-router-dom";
import { findCountryByAlpha3 } from "~/helpers/countryCodes";
import { TextSmall } from "../Typography";
import PhoneInput from "../PhoneInput";
import SubmitButton from "../SubmitButton";
import gql from "graphql-tag";
import styles from "./index.module.scss";
import { Provider } from "~/helpers/constants";

enum Field {
  Email = "NoAvailableCoverage-Email",
  Name = "NoAvailableCoverage-Name",
  CountryCode = "NoAvailableCoverage-CountryCode",
  PhoneNumber = "NoAvailableCoverage-PhoneNumber",
}

const REFER_CUSTOMER = gql`
  mutation referCustomer(
    $email: String!
    $name: String!
    $phoneNumber: String!
    $provider: String!
  ) {
    referCustomer(
      email: $email
      name: $name
      phoneNumber: $phoneNumber
      provider: $provider
    )
  }
`;

type Props = {
  setSubmitted: (value: SetStateAction<boolean>) => void;
  provider: Provider;
};

export default function ReferralForm(props: Props) {
  const history = useHistory();
  const userCtx = useUserContext();

  const phoneRequiredError = "Please enter a valid phone number";
  const [referCustomer] = useMutation(REFER_CUSTOMER);
  const { value: email } = useCustomFieldState(Field.Email);
  const { value: name } = useCustomFieldState(Field.Name);
  const { value: countryCode } = useCustomFieldState(Field.CountryCode);
  const { value: phoneNumber } = useCustomFieldState(Field.PhoneNumber);
  const { invalid } = useFormState();

  const pathMatch: {
    params: { countryCode: CountryCode; insuranceType: InsuranceType };
  } | null = matchPath(history.location.pathname, {
    path: "/:countryCode/:insuranceType/:step",
    exact: true,
    strict: false,
  });

  const countryCodeInitialValue = useMemo(() => {
    if (userCtx.countryPhoneCode) {
      const contextCountryCode = findCountryByAlpha3(userCtx.countryPhoneCode);

      if (contextCountryCode) {
        return contextCountryCode.alpha3;
      }
    }

    if (pathMatch) {
      const paramCountryCode = findCountryByAlpha3(
        pathMatch.params.countryCode.toUpperCase()
      );

      if (paramCountryCode) {
        return paramCountryCode.alpha3;
      }
    }
    return "BRB";
  }, [userCtx.countryPhoneCode, pathMatch]);

  async function handleOnSubmit() {
    if (!(email && name && phoneNumber && countryCode)) return;
    const val = phoneNumber.toString().replace(/[^\d]/g, "");
    const selectedCountry = findCountryByAlpha3(countryCode);

    if (selectedCountry === undefined) return;
    const diallingCode = selectedCountry.diallingCode;
    userCtx.setState({
      email: email as string,
      name: name as string,
      phoneNumber: `${val}`,
      diallingCode,
      countryPhoneCode: countryCode as string,
    });

    const response = await referCustomer({
      variables: {
        email: email as string,
        name: name as string,
        phoneNumber: `${diallingCode}${val}`,
        provider: props.provider,
      },
    });
    if (response.data.referCustomer) {
      props.setSubmitted(true);
    }
  }
  return (
    <>
      <TextInput
        type="text"
        field={Field.Name}
        label="Name"
        placeholder="Name"
        initialValue={userCtx.name}
        validate={isFullName("Please enter a valid name")}
        validateOnChange
        validateOnMount
      />
      <TextInput
        type="text"
        field={Field.Email}
        label="Email"
        placeholder="Email"
        initialValue={userCtx.email}
        validate={validateEmail}
        validateOnChange
        validateOnMount
      />
      <TextSmall>Phone Number</TextSmall>
      <PhoneInput
        countryCodeFieldName={Field.CountryCode}
        phoneFieldName={Field.PhoneNumber}
        countryCodeInitialValue={countryCodeInitialValue}
        phoneInitialValue={userCtx.phoneNumber?.toString()}
        label="Phone Number"
        phoneRequiredError={phoneRequiredError}
        validate={(diallingCode, phoneNumber) => {
          const phoneReqFunction = phoneRequired(
            phoneRequiredError,
            diallingCode
          );
          if (phoneReqFunction(phoneNumber) === undefined) {
            return undefined;
          }
          return phoneRequiredError;
        }}
      />
      <div className={styles.ButtonWrapper}>
        <SubmitButton
          disabled={invalid || !(email && name && countryCode && phoneNumber)}
          onClick={handleOnSubmit}
        >
          Submit
        </SubmitButton>
      </div>
    </>
  );
}
