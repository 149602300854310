import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { required } from "~/helpers/validators";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function YearOfConstruction({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 15,
      totalOfSteps: 24,
      title: "Property Details",
    });
  });

  const fieldName = "yearOfConstruction";
  const { value, error } = useCustomFieldState(fieldName);

  const handleOnSave = async () => {
    await ihCtx.saveData(
      {
        constructionYear: Number(value.toString()),
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3>What year was your home constructed?</H3>
      </legend>

      <div className={styles.TextInputWrapper}>
        <TextInput
          hideLabelFromView
          field={fieldName}
          placeholder="Year"
          type="year"
          keepState
          validate={required("Please enter the year your home was constructed")}
          validateOnBlur
          validateOnChange
          autoFocus
          size={4}
          initialValue={ihCtx.data?.constructionYear?.toString()}
          inputMode="numeric"
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="YearOfConstruction-SubmitButton"
          className={styles.ButtonNext}
          disabled={!!(error || !value || !String(value)?.length)}
          onClick={handleOnSave}
        >
          Next
        </SubmitButton>
      </div>
    </fieldset>
  );
}
