import React from "react";
import Checkbox from "~/components/Checkbox";
import LoadingWrapper from "~/components/LoadingWrapper";
import TileCard from "~/components/TileCard";
import { H3 } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import classnames from "classnames";
import Icon from "~/components/Icon";

import styles from "./index.module.scss";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";

type ButtonOption = {
  label: string;
  value: string;
};

type Props = {
  onChange: (values: string[]) => void;
  text: string;
  initialValues: string[];
  buttonOptions: ButtonOption[];
  fieldName: string;
  hideOther?: boolean;
  otherCustomlabel?: string;
  header: {
    currentStep: number;
    totalSteps: number;
    title: string;
  };
};

export default function MultiSelectAndOther({
  onChange,
  text,
  initialValues,
  buttonOptions,
  fieldName,
  hideOther,
  otherCustomlabel,
  header,
}: Props) {
  const headerCtx = useHeaderContext();
  const [saving, setSaving] = React.useState<boolean>(false);
  const [selections, setSelections] = React.useState<string[]>([]);
  const otherFieldName = `${fieldName}-otherField`;
  const { value: otherValue } = useCustomFieldState(otherFieldName);
  const otherFieldApi = useCustomFieldApi(otherFieldName);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: header.currentStep,
      totalOfSteps: header.totalSteps,
      title: header.title,
    });

    const starterSelections: string[] = [];
    let starterOther = "";

    initialValues.forEach((initVal) => {
      if (
        buttonOptions.some((option) => {
          return initVal === option.value;
        })
      ) {
        starterSelections.push(initVal);
      } else {
        starterOther = initVal;
      }
    });

    setSelections(starterSelections);

    if (starterOther) {
      otherFieldApi.setValue(starterOther);
    }
  });

  const handleSaveClick = () => {
    const otherVal = otherValue?.toString();

    try {
      setSaving(true);

      onChange([...selections, ...(otherVal ? [otherVal] : [])]);
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  const onSelect = (value: string) => {
    const newSelections = [...selections];

    const index = newSelections.findIndex((selection) => {
      return selection === value;
    });

    if (index === -1) {
      newSelections.push(value);
    } else {
      newSelections.splice(index, 1);
    }

    setSelections(newSelections);
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>{text}</H3>

          <div className={styles.SelectionContainer}>
            {buttonOptions.map((option) => {
              const isSelected = selections.some((selection) => {
                return selection === option.value;
              });

              return (
                <TileCard
                  key={option.value}
                  title={option.label}
                  inputId={option.value}
                  checked={isSelected}
                  inputElement={
                    <Checkbox
                      id={option.value}
                      field={fieldName}
                      className={styles.HideCheckbox}
                      onChange={() => onSelect(option.value)}
                    />
                  }
                  left={
                    <>
                      <div
                        className={classnames(styles.CheckBoxIcon, {
                          [styles.Checked]: isSelected,
                        })}
                      >
                        {isSelected && (
                          <Icon name="CheckSquare" backgroundColor="#FF6B28" />
                        )}
                      </div>
                    </>
                  }
                  className={styles.TileCard}
                />
              );
            })}
          </div>

          {!hideOther && (
            <TextInput
              label={otherCustomlabel ?? "Other"}
              field={otherFieldName}
              type="text"
              keepState
              hideLabelFromView
              placeholder={otherCustomlabel ?? "Other"}
            />
          )}

          <SubmitButton
            id="SubitButton"
            className={styles.ContinueButton}
            onClick={handleSaveClick}
          >
            Continue
          </SubmitButton>
        </>
      )}
    </>
  );
}
