import * as storage from "~/helpers/storage";
import { FormValue } from "informed";
import { CoverageType, PolicyStatus } from "../../../types";
import { POLICY_QUOTE_STATUS } from "~/helpers/constants";

export type ValuesByNumberOfInstallments = {
  twelve: number;
};

export type QuoteCoveredItem = {
  coveredValue: number;
  peril:
    | "third-party-casualty"
    | "third-party-casualties"
    | "third-party-property"
    | "own-vehicle";
  isVariableExcess: "true" | "false";
  excessValue: number;
};

export type Quote = {
  typeOfCover: CoverageType;
  basePremiumValue: number;
  netPremiumValue: number;
  taxValue: number;
  totalValue: number;
  coverage: QuoteCoveredItem[];
  valueByNumberOfInstallments: {
    twelve: number;
  };
};

export type PolicyInfoInputs = {
  id?: string;
  coverageStart?: string;
  status?: PolicyStatus;
  isForBusiness?: "yes" | "no";
  isLowAgeDriver?: "yes" | "no";
  isLowExperienceDriver?: "yes" | "no";
  drivers: Array<{ name: string; age: string; yearsDriving: string }>;
  isShiftWorker?: "yes" | "no";
  claimsHistory?: "0" | "<=2" | ">2";
  yearsWithoutClaims?: number;
  yearsDriving?: number;
  typeOfCover?: CoverageType;
  basePremiumValue?: number;
  netPremiumValue?: number;
  taxValue?: number;
  totalValue?: number;
  coverage?: QuoteCoveredItem[];
  valueByNumberOfInstallments?: ValuesByNumberOfInstallments;
  age?: number;
  generatedQuotes?: Quote[] | undefined;
};

const defaultValue: PolicyInfoInputs = {
  id: undefined,
  coverageStart: undefined,
  status: POLICY_QUOTE_STATUS.quote as PolicyStatus,
  isForBusiness: undefined,
  isLowAgeDriver: undefined,
  isLowExperienceDriver: undefined,
  drivers: [],
  isShiftWorker: undefined,
  claimsHistory: undefined,
  yearsWithoutClaims: undefined,
  yearsDriving: undefined,
  typeOfCover: undefined,
  basePremiumValue: undefined,
  netPremiumValue: undefined,
  taxValue: undefined,
  totalValue: undefined,
  coverage: undefined,
  valueByNumberOfInstallments: undefined,
  age: undefined,
  generatedQuotes: undefined,
};

const keyStore = "policyInfo";

export const POLICY_INFO_DEFAULT_STATE = () => {
  let savedValue = storage.getItem(keyStore) as object;
  savedValue = !savedValue ? {} : savedValue;
  return {
    data: { ...defaultValue, ...savedValue },
    setItem(key: string, value: FormValue) {
      this.data[key] = value;
      storage.saveItem(keyStore, this.data);
    },
    removeItem(key: string) {
      this.data[key] = undefined;
      storage.saveItem(keyStore, this.data);
    },
    removeStore() {
      this.data = { ...defaultValue };
      storage.removeItem(keyStore);
    },
  };
};
