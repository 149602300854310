import React from "react";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import { useHistory } from "react-router";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3, H5 } from "~/components/Typography";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHomeContext } from "~/contexts/HomeProvider";
import { required } from "~/helpers/validators";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

enum Field {
  Value = "SeawallValueField",
  Details = "SeawallDetailsField",
  ConstructionYear = "SeawallConstructionYearField",
  Material = "SeawallMaterialField",
}

export default function IHSeawall({ nextPath }: Props) {
  const history = useHistory();
  const homeCtx = useHomeContext();

  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);

  const { value: valueValue } = useCustomFieldState(Field.Value);
  const { value: detailsValue } = useCustomFieldState(Field.Details);
  const { value: materialValue } = useCustomFieldState(Field.Material);
  const { value: constructionYearValue } = useCustomFieldState(
    Field.ConstructionYear
  );

  const handleRadioClick = (yes: boolean) => {
    if (yes) {
      setShowDetails(true);
    } else {
      setSaving(true);

      homeCtx.setState({
        islandHeritageSeawall: undefined,
      });

      if (nextPath) {
        history.push(nextPath);
      }
    }
  };

  const handleSaveClick = () => {
    setSaving(true);

    homeCtx.setState({
      islandHeritageSeawall: {
        value: cleanNumberInput(valueValue),
        details: detailsValue?.toString(),
        constructionYear: Number(constructionYearValue) ?? 0,
        material: materialValue?.toString(),
      },
    });

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <fieldset>
          <legend>
            <H3>Seawalls, Docks, Piers or Jetties</H3>
            <H5>Do you require this coverage?</H5>
          </legend>

          <RadioBinary
            handleClick={handleRadioClick}
            field="radioBinary"
            thin
          />

          {showDetails && (
            <>
              <div className={styles.TextInputWrapperSlim}>
                <TextInput
                  field={Field.Value}
                  type="currency"
                  keepState
                  label="Value"
                  placeholder="$ 0"
                  validate={required("Please enter value")}
                  validateOnBlur
                  validateOnChange
                  autoFocus
                  size={6}
                  inputMode="numeric"
                  initialValue={homeCtx.islandHeritageSeawall?.value}
                />
              </div>

              <div className={styles.TextInputWrapperSlim}>
                <TextInput
                  field={Field.Details}
                  type="text"
                  keepState
                  label="Details"
                  placeholder="Details"
                  validate={required("Please enter details")}
                  validateOnBlur
                  validateOnChange
                  autoFocus
                  inputMode="numeric"
                  initialValue={homeCtx.islandHeritageSeawall?.details}
                />
              </div>

              <div className={styles.TextInputWrapperSlim}>
                <TextInput
                  field={Field.ConstructionYear}
                  type="year"
                  keepState
                  label="Year constructed"
                  placeholder="1990"
                  validate={required("Please enter construction year")}
                  validateOnBlur
                  validateOnChange
                  autoFocus
                  inputMode="numeric"
                  initialValue={String(
                    homeCtx.islandHeritageSeawall?.constructionYear
                  )}
                />
              </div>

              <div className={styles.TextInputWrapperSlim}>
                <TextInput
                  field={Field.Material}
                  type="text"
                  label="Materials used"
                  keepState
                  placeholder="Materials"
                  validate={required("Please enter materials used")}
                  validateOnBlur
                  validateOnChange
                  autoFocus
                  initialValue={homeCtx.islandHeritageSeawall?.material}
                />
              </div>

              <div className={styles.ButtonWrapper}>
                <SubmitButton
                  id="Seawall-SubmitButton"
                  className={styles.ButtonNext}
                  disabled={
                    !!(
                      !valueValue ||
                      !detailsValue ||
                      !constructionYearValue ||
                      !materialValue
                    )
                  }
                  onClick={handleSaveClick}
                >
                  Next
                </SubmitButton>
              </div>
            </>
          )}
        </fieldset>
      )}
    </>
  );
}
