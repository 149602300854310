import React from "react";
import { useHistory } from "react-router";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import BooleanAndDetails from "~/screens/Home/PageShells/BooleanAndDetails";

type Props = {
  nextPath?: string;
};

export default function IHDLiability({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string | undefined) => {
    await ihCtx.saveData(
      {
        liablility: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <BooleanAndDetails
      onChange={handleChange}
      mainText="In the last 5 years, have you or the proposed property sustained
        any loss or damage or incurred liability?"
      detailText="Please provide full details below"
      fieldName="ihdLiability"
      initialValue={ihCtx.data?.liablility}
      header={{
        currentStep: 19,
        totalSteps: 24,
        title: "Property Details",
      }}
    />
  );
}
