import React from "react";
import { useHistory } from "react-router-dom";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

type Props = {
  nextPath?: string;
};

export default function DataCollectionComplete({ nextPath }: Props) {
  const ihCtx = useIslandHeritageContext();
  const history = useHistory();

  const onSubmit = async () => {
    const response = await ihCtx.submitData(ihCtx.policyId);

    if (!response) {
      // TODO: show error?
      return;
    }

    history.push(nextPath ?? "");
  };

  useMountEffect(() => {
    onSubmit();
  });

  return <div></div>;
}
