import React from "react";
import { H4, TextSmall } from "~/components/Typography";
import CoverageList from "~/components/CoverageList";
import CheckImg from "~/assets/images/check.png";
import PortalList from "~/components/PortalList";
import { format } from "date-fns";
import { getTypeOfCoverage } from "~/helpers/getCoverageData";
import { formatCurrency } from "~/helpers/currency";
import { PortalPolicyType, CoverageObjType } from "../../types";
import styles from "./index.module.scss";
import { formatIsoDate } from "~/helpers/dates";
import { Provider } from "~/helpers/constants";

type PropsType = {
  policy: PortalPolicyType;
};

type ListItemType = {
  title?: string;
  value?: string;
  subTitle?: string;
};

const BODILY_INJURY_COVERAGE = 10000000;
const PROPERTY_DAMAGE_COVERAGE = 1000000;

const PortalPlanCard = ({ policy }: PropsType) => {
  const [listData, setListData] = React.useState<ListItemType[]>([]);
  const [coverageType, setCoverageType] = React.useState(
    getTypeOfCoverage(policy.typeOfCover)
  );

  const getHomeSubTitle = () => {
    const title = policy.coverage.find((cover) => {
      return cover.peril === "home-contents-coverage";
    })
      ? "Property + Contents Insurance"
      : "Property Insurance";

    return title;
  };

  //Set excess and coverageType
  React.useEffect(() => {
    setCoverageType(getTypeOfCoverage(policy.typeOfCover));
  }, [policy.typeOfCover]);

  //Set portal list data
  React.useEffect(() => {
    const list: ListItemType[] = [];

    const getCoverage = (peril: CoverageObjType["peril"]) =>
      policy.coverage.find((cover) => cover.peril === peril);

    if (policy.policyObject === "auto") {
      list.push(
        {
          title: "Third Party Coverage",
          value: `${formatCurrency(
            getCoverage("third-party-casualty")?.coveredValue ?? 0,
            true
          )}`,
        },
        {
          title: "Bodily Injury Coverage",
          value: `${formatCurrency(BODILY_INJURY_COVERAGE, true)}`,
        },
        {
          title: "Property Damage Coverage",
          value: `${formatCurrency(PROPERTY_DAMAGE_COVERAGE, true)}`,
        }
      );

      // If the coverage is not third party, add it to the list
      if (policy.typeOfCover !== "T") {
        list.push({
          title: "Excess",
          value: `${formatCurrency(
            getCoverage("own-vehicle")?.excessValue ?? 0,
            true
          )}`,
        });
      }

      // If a vehicle value exists, add it to the list
      const vehicleValue = getCoverage("own-vehicle")?.coveredValue;

      if (vehicleValue) {
        list.push({
          title: "Vehicle Value Insured",
          value: `${formatCurrency(vehicleValue, true)}`,
        });
      }
    } else if (policy.policyObject === "home") {
      const propertyCoverage = policy.coverage.find((cover) => {
        return cover.peril === "home-property-coverage";
      });

      if (propertyCoverage) {
        list.push({
          title: "Property Coverage",
          value: `${formatCurrency(
            propertyCoverage.coveredValue,
            true
          )} | Excess: The excess will be 2% of the sum insured with a minimum of ${formatCurrency(
            propertyCoverage.excessValue,
            true
          )}`,
        });
      }

      const contentsCoverage = policy.coverage.find((cover) => {
        return cover.peril === "home-contents-coverage";
      });

      if (contentsCoverage) {
        list.push({
          title: "Contents Coverage",
          value: `${formatCurrency(
            contentsCoverage.coveredValue,
            true
          )} | Excess: The excess will be 2% of the sum insured with a minimum of ${formatCurrency(
            contentsCoverage.excessValue,
            true
          )}`,
        });
      }

      const allRiskCoverage = policy.coverage.find((cover) => {
        return cover.peril === "home-all-risks-coverage";
      });

      if (allRiskCoverage) {
        const title = allRiskCoverage.variantName
          ? `All Risk Coverage (${allRiskCoverage.variantName})`
          : "All Risk Coverage";

        list.push({
          title,
          value: `${formatCurrency(
            allRiskCoverage.coveredValue,
            true
          )} | Excess: ${formatCurrency(allRiskCoverage.excessValue, true)}`,
        });
      }

      list.push(
        {
          subTitle:
            "For a full breakdown of your excesses, \n please refer to your policy schedule.",
        },
        {
          title: "Public Liability",
          value: "$500,000",
        },
        {
          title: "Personal Liability",
          value: "$250,000",
        }
      );
    }

    console.log("Policy: ", policy);

    list.push(
      {
        title: "Producer",
        value:
          policy.provider === Provider.IslandHeritage
            ? "Island Heritage"
            : "Guardian General Insurance Limited",
      },
      {
        title: "Coverage period",
        value: `${formatIsoDate(
          policy.coverageStart,
          "MMM dd, yyyy"
        )} - ${formatIsoDate(policy.coverageEnd, "MMM dd, yyyy")}`,
      },
      {
        title: "Original Inception",
        value: `${format(new Date(policy.createdAt), "MMM dd, yyyy")}`,
      },
      { title: "Insured(s)", value: policy.holder.name } // TODO: We will need to pull joint insurer from checklist
    );

    setListData(list);
  }, [coverageType, policy]);

  return (
    <div className={styles.Plan} role="group" aria-label="policy details">
      <div className={styles.PlanIcon}>
        <img
          src={CheckImg}
          alt="check mark"
          className={styles.CheckImg}
          aria-hidden
        />
      </div>

      <div className={styles.PlanHeading}>
        <TextSmall>
          {policy.policyObject === "auto"
            ? coverageType?.subTitle
            : getHomeSubTitle()}
        </TextSmall>
        <H4 component="h3">{coverageType?.title}</H4>
      </div>

      <div className={styles.PlanSubHeading}>
        <TextSmall fontWeight="bold">Policy Details</TextSmall>
      </div>

      <div className={styles.PlanChecklist}>
        <CoverageList
          typeOfCover={policy.typeOfCover}
          coverage={policy.coverage}
          showNotCovered={false}
          itemFilterPredicate={(item) => {
            if (item.id === "1000-deductible") return false;
            if (item.id === "third-party" && policy.typeOfCover !== "T")
              return false;
            return true;
          }}
        />
      </div>

      <div className={styles.PlanDetails}>
        <PortalList data={listData} />
      </div>
    </div>
  );
};

export default PortalPlanCard;
