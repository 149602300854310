import React from "react";
import { useHistory } from "react-router";
import LoadingWrapper from "~/components/LoadingWrapper";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H4, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function IHDPayees({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 24,
      totalOfSteps: 24,
      title: "Property Details",
    });
  });

  const [saving, setSaving] = React.useState<boolean>(false);

  const namesFieldName = "namesField";
  const { value: namesValue } = useCustomFieldState(namesFieldName);

  const relationshipsFieldName = "relationshipsField";
  const { value: relationshipsValue } = useCustomFieldState(
    relationshipsFieldName
  );

  useUpdateAssistant({
    text: (
      <TextSmall>
        Exclude anyone already named up to this point (mortgagee, joint insurer,
        etc.)
      </TextSmall>
    ),
  });

  const handleSaveClick = async () => {
    setSaving(true);

    await ihCtx.saveData(
      {
        interestedParties: namesValue ? namesValue.toString() : undefined,
        relation: relationshipsValue
          ? relationshipsValue.toString()
          : undefined,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <fieldset>
          <legend>
            <H4>Name all other persons with interest in this property</H4>
          </legend>

          <div className={styles.TextInputWrapperThin}>
            <TextInput
              hideLabelFromView
              field={namesFieldName}
              type="text"
              keepState
              initialValue={ihCtx.data?.interestedParties}
              placeholder="Names"
            />
          </div>

          <legend>
            <H4>
              Please state your relationships with the persons listed above
            </H4>
          </legend>

          <div className={styles.TextInputWrapperThin}>
            <TextInput
              hideLabelFromView
              field={relationshipsFieldName}
              type="text"
              keepState
              initialValue={ihCtx.data?.relation}
              placeholder="Relationships"
            />
          </div>

          <div className={styles.ButtonWrapper}>
            <SubmitButton
              id="Payees-SubmitButton"
              className={styles.ButtonNext}
              onClick={handleSaveClick}
            >
              {namesValue || relationshipsValue ? "Next" : "Skip"}
            </SubmitButton>
          </div>
        </fieldset>
      )}
    </>
  );
}
