import { IHomeContext } from "~/contexts/HomeProvider";
import { CountryCode } from "../types";
import getBranchFromCountryCode from "~/helpers/getBranchFromCountryCode";
import { formatUkDate } from "./dates";

type HomePolicyContentsType = {
  name: string;
  value: number;
  purchaseDate: string;
  isElectronic: boolean;
  identificationNumber?: string;
};

const formHomePolicyPayload = (
  homeInfo: IHomeContext,
  email: string | undefined,
  isPaymentRecurring: boolean,
  countryCode: CountryCode,
  toCreate: boolean
) => {
  //We need to split contents into proper sections now
  //that we're done working with them in one array
  const contentsItems: HomePolicyContentsType[] = [];
  const allRiskItems: HomePolicyContentsType[] = [];

  if (homeInfo.contents) {
    homeInfo.contents.forEach((content) => {
      if (content.isAllRisk) {
        allRiskItems.push({
          name: content.name,
          value: content.value,
          purchaseDate: formatUkDate(content.purchaseDate),
          identificationNumber: content.identificationNumber,
          isElectronic: content.isElectronic,
        });
      } else {
        contentsItems.push({
          name: content.name,
          value: content.value,
          purchaseDate: formatUkDate(content.purchaseDate),
          identificationNumber: content.identificationNumber,
          isElectronic: content.isElectronic,
        });
      }
    });
  }

  const allRiskValue = homeInfo.getAllRiskItemsTotal();
  const contentsValue = homeInfo.getCalculatedContentsValue();

  return {
    branch: getBranchFromCountryCode(countryCode),
    coverageStart: homeInfo.coverageStart,
    holderEmail: email,
    typeOfCover: toCreate ? undefined : homeInfo.typeOfCover,
    homeProposal: {
      allRiskContents: allRiskItems,
      allRiskCoverage: !!homeInfo.allRisk,
      allRiskValue: !homeInfo.allRisk ? undefined : allRiskValue,
      contents: contentsItems,
      contentsValue,
      isAllRiskWorldwide: homeInfo.allRiskType === "worldwide",
      isExistingMotorCustomer: homeInfo.hasExistingMotorPolicy,
      property: {
        address: homeInfo.address,
        buildingType: homeInfo.buildingType,
        constructionType: homeInfo.constructionType,
        isCoastal: homeInfo.isCoastal,
        isCommercial: homeInfo.commercialActivities,
        isFloodRisk: homeInfo.isFloodZone,
        isHighDensity: homeInfo.isHighDensity,
        isGoodState: homeInfo.isGoodState,
        value: homeInfo.replacementValue,
      },
      islandHeritageAddressInfo: homeInfo.islandHeritageAddressInfo,
      islandHeritageLocation: homeInfo.islandHeritageLocation,
      islandHeritageOccupiedBy: homeInfo.islandHeritageOccupiedBy,
      islandHeritageAppliances: homeInfo.islandHeritageAppliances,
      islandHeritageBuildings: homeInfo.islandHeritageBuildings,
      islandHeritageOutbuildings: homeInfo.islandHeritageOutbuildings,
      islandHeritageSeawall: homeInfo.islandHeritageSeawall,
    },
    id: toCreate ? undefined : homeInfo.policyId,
    isPaymentRecurring,
    policyObject: "home",
  };
};

export default formHomePolicyPayload;
