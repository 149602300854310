import { FormValue, RadioGroup } from "informed";
import React, { ReactElement } from "react";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioSelector from "~/components/RadioSelector";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

import styles from "~/styles/common/textInputPage.module.scss";

type ButtonOption = {
  label: string;
  value: string;
};

type Props = {
  onChange: (value: string) => void;
  text: string | ReactElement;
  fieldName: string;
  buttonOptions: ButtonOption[];
  hideOther?: boolean;
  otherCustomLabel?: string;
  initialValue?: string;
  header: {
    currentStep: number;
    totalSteps: number;
    title: string;
  };
};

export default function RadioAndOther({
  onChange,
  text,
  fieldName,
  buttonOptions,
  hideOther,
  otherCustomLabel,
  initialValue,
  header,
}: Props) {
  const headerCtx = useHeaderContext();
  const [saving, setSaving] = React.useState<boolean>(false);
  const otherFieldName = `${fieldName}-otherField`;
  const { value: otherValue } = useCustomFieldState(otherFieldName);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: header.currentStep,
      totalOfSteps: header.totalSteps,
      title: header.title,
    });
  });

  const handleNextClick = (value: FormValue) => {
    try {
      setSaving(true);
      onChange(value.toString());
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <fieldset>
          <legend>
            <H3>{text}</H3>
          </legend>

          <div className={styles.RadioContent}>
            <RadioGroup field={fieldName}>
              {buttonOptions.map((option) => (
                <RadioSelector
                  key={option.value}
                  field={fieldName}
                  value={option.value}
                  checked={initialValue === option.value}
                  onChange={() => handleNextClick(option.value)}
                >
                  {option.label}
                </RadioSelector>
              ))}
            </RadioGroup>
          </div>

          {!hideOther && (
            <>
              <div className={styles.TextInputWrapper}>
                <TextInput
                  label={otherCustomLabel ?? "Other"}
                  field={otherFieldName}
                  initialValue={
                    initialValue &&
                    !buttonOptions.find(
                      (option) => option.value === initialValue
                    )
                      ? initialValue
                      : undefined
                  }
                  type="text"
                  hideLabelFromView
                  keepState
                  placeholder={otherCustomLabel ?? "Other"}
                />
              </div>

              {otherValue?.toString() && (
                <div className={styles.ButtonWrapper}>
                  <SubmitButton
                    id={`${fieldName}-SubmitButton`}
                    className={styles.ButtonNext}
                    disabled={!otherValue?.toString()?.trim()}
                    onClick={() => handleNextClick(otherValue)}
                  >
                    Next
                  </SubmitButton>
                </div>
              )}
            </>
          )}
        </fieldset>
      )}
    </>
  );
}
