import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3, Text } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { required } from "~/helpers/validators";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function NumberOfStoreys({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 13,
      totalOfSteps: 24,
      title: "Construction",
    });
  });

  const fieldName = "numberOfStoreys";
  const { value, error } = useCustomFieldState(fieldName);

  const handleOnSave = async () => {
    await ihCtx.saveData(
      {
        numberOfStoreys: Number(value.toString()),
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3>How many storeys/floors is your home?</H3>

        <Text>
          If you have an apartment or condominium, which floor is it on?
        </Text>
      </legend>

      <div className={styles.TextInputWrapperThin}>
        <TextInput
          hideLabelFromView
          field={fieldName}
          placeholder="3"
          type="number"
          size={3}
          keepState
          validate={required(
            "Please enter the number of storeys, or floor number"
          )}
          validateOnBlur
          validateOnChange
          autoFocus
          initialValue={ihCtx.data?.numberOfStoreys?.toString()}
          inputMode="numeric"
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="NumberOfStoreys-SubmitButton"
          className={styles.ButtonNext}
          disabled={!!(error || !value || !String(value)?.length)}
          onClick={handleOnSave}
        >
          Next
        </SubmitButton>
      </div>
    </fieldset>
  );
}
