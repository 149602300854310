import React from "react";
import { useHistory } from "react-router-dom";
import { TextSmall } from "~/components/Typography";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import RadioAndOther from "~/screens/Home/PageShells/RadioAndOther";

type Props = {
  nextPath?: string;
};

export default function PropertyOccupation({ nextPath }: Props) {
  const history = useHistory();
  const homeCtx = useHomeContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        A short-term rental would be considered less than a month.
      </TextSmall>
    ),
  });

  const handleChange = (value: string) => {
    homeCtx.setState({
      islandHeritageOccupiedBy: value,
    });

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <RadioAndOther
      onChange={handleChange}
      text="How will your home be occupied?"
      fieldName="propertyOccupation"
      hideOther
      buttonOptions={[
        {
          label: "Owner Occupied",
          value: "owner",
        },
        {
          label: "Short Term Rental",
          value: "short-rental",
        },
        {
          label: "Long Term Rental",
          value: "long-rental",
        },
      ]}
      header={{
        currentStep: 6,
        totalSteps: 6,
        title: "Property Details",
      }}
      initialValue={homeCtx.islandHeritageOccupiedBy}
    />
  );
}
