import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useFieldState } from "informed";
import styles from "./index.module.scss";
import hasErrorCode from "~/helpers/hasErrorCode";
import { ToolProps } from "../..";
import Checkbox from "~/components/Checkbox";

enum Field {
  CoverageDate = "adminRegenerateCoverNote-CoverageDateField",
  EngineCapacity = "adminRegenerateCoverNote-EngineCapacityField",
  EngineNumber = "adminRegenerateCoverNote-EngineNumberField",
  EngineChassis = "adminRegenerateCoverNote-EngineChasisField",
  HolderName = "adminRegenerateCoverNote-HolderNameField",
  IsRenewal = "adminRegenerateCoverNote-IsRenewalField",
  Passcode = "adminRegenerateCoverNote-Passcode",
}

const REGENRATE_COVERNOTE = gql`
  mutation adminRegenerateCoverNote(
    $policyId: String!
    $coverageDate: String
    $engineCapacity: String
    $engineNumber: String
    $engineChassis: String
    $holderName: String
    $isRenewal: Boolean
    $passcode: String!
  ) {
    adminRegenerateCoverNote(
      policyId: $policyId
      coverageDate: $coverageDate
      engineCapacity: $engineCapacity
      engineNumber: $engineNumber
      engineChassis: $engineChassis
      holderName: $holderName
      isRenewal: $isRenewal
      passcode: $passcode
    )
  }
`;

const AdminRegenerateCoverNote = ({ identifier, onClose }: ToolProps) => {
  const coverageDateState = useFieldState(Field.CoverageDate);
  const engineCapacityState = useFieldState(Field.EngineCapacity);
  const engineNumberState = useFieldState(Field.EngineNumber);
  const engineChassisState = useFieldState(Field.EngineChassis);
  const holderNameState = useFieldState(Field.HolderName);
  const isRenewalState = useFieldState(Field.IsRenewal);
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [regenerateCoverNote] = useMutation(REGENRATE_COVERNOTE);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await regenerateCoverNote({
        variables: {
          policyId: identifier,
          coverageDate: String(coverageDateState.value ?? ""),
          engineCapacity: String(engineCapacityState.value ?? ""),
          engineNumber: String(engineNumberState.value ?? ""),
          engineChassis: String(engineChassisState.value ?? ""),
          holderName: String(holderNameState.value ?? ""),
          isRenewal: !!isRenewalState.value,
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);

      if (response?.data?.adminRegenerateCoverNote) {
        onClose({
          manuallyClosed: false,
          success: true,
          shouldReload: false,
          message: "Cover note regenerated.",
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <TextInput
        type="date"
        label={"Coverage Date"}
        placeholder="DD/MM/YYYY"
        field={Field.CoverageDate}
      />

      <TextInput
        type="text"
        label={"Engine Capacity / CC"}
        placeholder="0000"
        field={Field.EngineCapacity}
      />

      <TextInput
        type="text"
        label={"Engine Number"}
        placeholder="G16AID318681"
        field={Field.EngineNumber}
      />

      <TextInput
        type="text"
        label={"Engine Chassis"}
        placeholder="MHYDN71V4LJ403091"
        field={Field.EngineChassis}
      />

      <TextInput
        type="text"
        label={"Holder Name"}
        placeholder="John Doe"
        field={Field.HolderName}
      />

      <Checkbox
        className={styles.SmallMarginTop}
        field={Field.IsRenewal}
        label="Is Policy Renewal"
        initialValue={false}
      />

      <TextInput
        type="text"
        label="Passcode"
        placeholder="Passcode"
        field={Field.Passcode}
      />

      <Button
        disabled={!passcodeState.value || processing}
        onClick={handleSubmit}
      >
        {processing ? "Processing..." : "Process"}
      </Button>
    </div>
  );
};

export default AdminRegenerateCoverNote;
