import React from "react";
import { useHistory } from "react-router-dom";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import RadioAndOther from "~/screens/Home/PageShells/RadioAndOther";

type Props = {
  nextPath?: string;
};

export default function CosntructionRoofAnchor({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string) => {
    await ihCtx.saveData(
      {
        roofAnchor: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <RadioAndOther
      onChange={handleChange}
      text={
        <>
          What are the <u>anchors for your roof</u> made with?
        </>
      }
      fieldName="constructionRoofAnchor"
      buttonOptions={[
        {
          label: "Hurricane Clips",
          value: "hurricane-clips",
        },
        {
          label: "Rebar",
          value: "rebar",
        },
      ]}
      header={{
        currentStep: 9,
        totalSteps: 24,
        title: "Construction",
      }}
      initialValue={ihCtx.data.roofAnchor}
    />
  );
}
