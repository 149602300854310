import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3, H4 } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function LastRenovation({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 16,
      totalOfSteps: 24,
      title: "Property Details",
    });
  });

  const yearFieldName = "yearOfLastRenovation";
  const detailsFieldName = "renovationDetails";
  const { value: yearValue, error: yearError } = useCustomFieldState(
    yearFieldName
  );
  const { value: detailsValue, error: detailsError } = useCustomFieldState(
    detailsFieldName
  );

  const handleOnSave = async () => {
    if (!yearValue) {
      await ihCtx.saveData(
        {
          renovationYear: undefined,
          renovationDetails: undefined,
        },
        nextPath ?? ""
      );
    } else {
      await ihCtx.saveData(
        {
          renovationYear: Number(yearValue.toString()),
          renovationDetails: detailsValue.toString(),
        },
        nextPath ?? ""
      );
    }

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3>When was your home last renovated?</H3>
      </legend>

      <div className={styles.TextInputWrapper}>
        <TextInput
          hideLabelFromView
          field={yearFieldName}
          placeholder="2000"
          type="year"
          keepState
          autoFocus
          size={4}
          initialValue={ihCtx.data?.renovationYear?.toString()}
          inputMode="numeric"
        />
      </div>

      {!!yearValue && (
        <>
          <legend>
            <H4>Please let us know the details of the renovation below</H4>
          </legend>

          <div className={styles.TextInputWrapper}>
            <TextInput
              hideLabelFromView
              field={detailsFieldName}
              placeholder="Details"
              type="text"
              keepState
              initialValue={ihCtx.data?.renovationDetails?.toString()}
              inputMode="text"
            />
          </div>
        </>
      )}

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="FloorArea-SubmitButton"
          className={styles.ButtonNext}
          disabled={
            (!!yearValue && !detailsValue) || !!yearError || !!detailsError
          }
          onClick={handleOnSave}
        >
          {yearValue ? "Next" : "No Renovations"}
        </SubmitButton>
      </div>
    </fieldset>
  );
}
