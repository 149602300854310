import React from "react";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import hasErrorCode from "~/helpers/hasErrorCode";
import { ToolProps } from "../..";
import styles from "./index.module.scss";

enum Field {
  PaymentRecordId = "AdminMarkFirstPaymentSuccessful-PaymentRecordId",
  Passcode = "AdminMarkFirstPaymentSuccessful-Passcode",
}

const MARK_FIRST_PAYMENT_SUCCESSFUL = gql`
  mutation AdminMarkFirstPaymentSuccessful(
    $policyId: String!
    $paymentRecordId: String!
    $passcode: String!
  ) {
    adminMarkFirstPaymentSuccessful(
      policyId: $policyId
      paymentRecordId: $paymentRecordId
      passcode: $passcode
    )
  }
`;

const AdminMarkFirstPaymentSuccessful = ({
  identifier,
  onClose,
}: ToolProps) => {
  const paymentRecordIdState = useFieldState(Field.PaymentRecordId);
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [markFirstPaymentSuccessful] = useMutation(
    MARK_FIRST_PAYMENT_SUCCESSFUL
  );

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      await markFirstPaymentSuccessful({
        variables: {
          policyId: identifier,
          paymentRecordId: String(paymentRecordIdState.value),
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);

      onClose({
        manuallyClosed: false,
        success: true,
        shouldReload: true,
        message: `Policy successfully recovered!`,
      });
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <>
        <TextInput
          type="text"
          label="Payment Record ID"
          placeholder="XXXX-XXXX-XXXX"
          field={Field.PaymentRecordId}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={
            !paymentRecordIdState.value || !passcodeState.value || processing
          }
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminMarkFirstPaymentSuccessful;
