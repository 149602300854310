import React from "react";
import { useHistory } from "react-router";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import BooleanAndDetails from "~/screens/Home/PageShells/BooleanAndDetails";

type Props = {
  nextPath?: string;
};

export default function IHDMortgagee({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string | undefined) => {
    await ihCtx.saveData(
      {
        mortgagee: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <BooleanAndDetails
      onChange={handleChange}
      mainText="Do you currently have a mortgage on this property?"
      detailText="Please provide name of the interested party"
      fieldName="ihdMortgagee"
      initialValue={ihCtx.data?.mortgagee}
      header={{
        currentStep: 23,
        totalSteps: 24,
        title: "Property Details",
      }}
    />
  );
}
