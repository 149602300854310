import React from "react";
import { useHistory } from "react-router-dom";
import { TextSmall } from "~/components/Typography";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import RadioAndOther from "~/screens/Home/PageShells/RadioAndOther";

type Props = {
  nextPath?: string;
};

export default function PropertyLocation({ nextPath }: Props) {
  const history = useHistory();
  const homeCtx = useHomeContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        If your property is less than 200m from the coast it is considered a
        beachfront property.
      </TextSmall>
    ),
  });

  const handleChange = async (value: string) => {
    await homeCtx.setState({
      islandHeritageLocation: value,
    });

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <RadioAndOther
      onChange={handleChange}
      text="Describe the location of your home's property"
      fieldName="propertyLocation"
      buttonOptions={[
        {
          label: "Inland",
          value: "inland",
        },
        {
          label: "Beachfront",
          value: "seaside",
        },
        /*{
          label: "On a Canal",
          value: "canalside",
        },*/
      ]}
      header={{
        currentStep: 6,
        totalSteps: 6,
        title: "Property Details",
      }}
      initialValue={homeCtx.islandHeritageLocation}
    />
  );
}
