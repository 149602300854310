import React from "react";
import gql from "graphql-tag";
import AdminTable, {
  AdminTableColumn,
  AdminTableRow,
} from "~/components/AdminTable";
import { useHistory, useLocation } from "react-router-dom";
import { ADMIN_DASHBOARD_URL } from "~/routes/index.constant";
import { useLazyQuery } from "@apollo/react-hooks";
import { H3, Text } from "~/components/Typography";
import styles from "./index.module.scss";
import AdminPagination from "~/components/AdminPagination";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useFieldState, useFormApi } from "informed";

enum Field {
  Email = "AdminCustomerListView-Email",
  FirstName = "AdminCustomerListView-FirstName",
  LastName = "AdminCustomerListView-LastName",
}

type Customer = {
  customerId: string;
  webstarId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  createdDate: Date;
};

type GetCustomerList = {
  adminCustomerListView: {
    customers: Customer[];
    count?: number;
  };
};

const GET_CUSTOMER_LIST = gql`
  query adminCustomerListView(
    $email: String
    $firstName: String
    $lastName: String
    $sortField: String
    $sortDesc: Boolean
    $page: Float
    $perPage: Float
    $getDocumentCount: Boolean
  ) {
    adminCustomerListView(
      email: $email
      firstName: $firstName
      lastName: $lastName
      sortField: $sortField
      sortDesc: $sortDesc
      page: $page
      perPage: $perPage
      getDocumentCount: $getDocumentCount
    ) {
      customers {
        customerId
        webstarId
        email
        firstName
        lastName
        createdDate
      }
      count
    }
  }
`;

const AdminCustomerListView = () => {
  const location = useLocation();

  const formApi = useFormApi();
  const emailState = useFieldState(Field.Email);
  const firstNameState = useFieldState(Field.FirstName);
  const lastNameState = useFieldState(Field.LastName);

  const getParam = (param: string) => {
    return new URLSearchParams(location.search).get(param) ?? undefined;
  };

  const [options, setOptions] = React.useState<{
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    sortField: string | undefined;
    sortDesc: boolean;
    selectedPage: number;
    perPage: number;
    itemCountIncrement: number;
  }>({
    email: getParam("email"),
    firstName: getParam("firstName"),
    lastName: getParam("lastName"),
    sortField: getParam("sortField") ?? "createdDate",
    sortDesc: getParam("sortDesc") === "false" ? false : true,
    selectedPage: 1,
    perPage: 5,
    itemCountIncrement: 1,
  });

  useMountEffect(() => {
    formApi.setValue(Field.Email, getParam("email") ?? "");
    formApi.setValue(Field.FirstName, getParam("firstName") ?? "");
    formApi.setValue(Field.LastName, getParam("lastName") ?? "");
  });

  const [tableColumns, setTableColumns] = React.useState<AdminTableColumn[]>(
    []
  );

  const [tableRows, setTableRows] = React.useState<AdminTableRow[]>([]);

  const [
    lastItemCountIncrement,
    setLastItemCountIncrement,
  ] = React.useState<number>(0);

  const [itemCount, setItemCount] = React.useState<number>(0);

  const history = useHistory();

  const openCustomerPage = (customerId: string) => {
    history.push(`${ADMIN_DASHBOARD_URL}/customer/${customerId}`);
  };

  const onChangePerPage = (newPerPage: number) => {
    setOptions((options) => ({
      ...options,
      perPage: newPerPage,
      selectedPage: 1,
    }));
  };

  const onChangePageSelected = (newPageSelected: number) => {
    setOptions((options) => ({
      ...options,
      selectedPage: newPageSelected,
    }));
  };

  const getDateString = (date: Date) => {
    return date.toLocaleDateString();
  };

  const handleChangeSort = (sort: string) => {
    if (sort === options.sortField) {
      setOptions((options) => ({
        ...options,
        sortDesc: !options.sortDesc,
        selectedPage: 1,
      }));
    } else {
      setOptions((options) => ({
        ...options,
        sortField: sort,
        sortDesc: false,
        selectedPage: 1,
      }));
    }
  };

  const updateSearch = () => {
    setOptions((options) => ({
      ...options,
      email: emailState.value ? String(emailState.value) : undefined,
      firstName: firstNameState.value
        ? String(firstNameState.value)
        : undefined,
      lastName: lastNameState.value ? String(lastNameState.value) : undefined,
      itemCountIncrement: (options.itemCountIncrement += 1),
      selectedPage: 1,
    }));
  };

  const [
    getCustomerList,
    { loading, error, data },
  ] = useLazyQuery<GetCustomerList>(GET_CUSTOMER_LIST, {
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    const newTableColumns: AdminTableColumn[] = [
      {
        displayName: "ROW #",
      },
      {
        displayName: "CUSTOMER ID",
      },
      {
        displayName: "WEBSTAR ID",
      },
      {
        displayName: "EMAIL",
        sortData: {
          sortable: true,
          sortField: "email",
          sortBy: options.sortField === "email",
          sortDesc: !!options.sortDesc,
        },
      },
      {
        displayName: "FIRST NAME",
        sortData: {
          sortable: true,
          sortField: "firstName",
          sortBy: options.sortField === "firstName",
          sortDesc: !!options.sortDesc,
        },
      },
      {
        displayName: "LAST NAME",
        sortData: {
          sortable: true,
          sortField: "lastName",
          sortBy: options.sortField === "lastName",
          sortDesc: !!options.sortDesc,
        },
      },
      {
        displayName: "CREATED DATE",
        sortData: {
          sortable: true,
          sortField: "createdDate",
          sortBy: options.sortField === "createdDate",
          sortDesc: !!options.sortDesc,
        },
      },
    ];

    setTableColumns(newTableColumns);

    getCustomerList({
      variables: {
        email: options.email,
        firstName: options.firstName,
        lastName: options.lastName,
        sortField: options.sortField,
        sortDesc: options.sortDesc,
        page: options.selectedPage,
        perPage: options.perPage,
        getDocumentCount: options.itemCountIncrement > lastItemCountIncrement,
      },
    });

    if (options.itemCountIncrement > lastItemCountIncrement) {
      setLastItemCountIncrement(options.itemCountIncrement);
    }

    const params = {
      ...(options.email && {
        email: options.email,
      }),
      ...(options.firstName && {
        firstName: options.firstName,
      }),
      ...(options.lastName && {
        lastName: options.lastName,
      }),
      sortField: options.sortField,
      sortDesc: options.sortDesc ? "true" : "false",
    };

    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`;

    window.history.replaceState({ path: newUrl }, "", newUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (data) {
      if (
        data.adminCustomerListView.count !== undefined &&
        data.adminCustomerListView.count !== null
      ) {
        setItemCount(data.adminCustomerListView.count);
      }

      const newTableRows: AdminTableRow[] = [];

      if (data.adminCustomerListView.customers) {
        data.adminCustomerListView.customers.forEach((customer, index) => {
          const cells: React.ReactElement[] = [
            <Text key={`cell-row-${index}`}>
              {String(index + 1 + (options.selectedPage - 1) * options.perPage)}
            </Text>,
            <Text key={`cell-customerId-${index}`}>{customer.customerId}</Text>,
            <Text key={`cell-webstarId-${index}`}>{customer.webstarId}</Text>,
            <Text key={`cell-email-${index}`}>{customer.email}</Text>,
            <Text key={`cell-firstName-${index}`}>{customer.firstName}</Text>,
            <Text key={`cell-lastName-${index}`}>{customer.lastName}</Text>,
            <Text key={`cell-createdDate-${index}`}>
              {getDateString(new Date(customer.createdDate))}
            </Text>,
          ];

          newTableRows.push({
            onClickRowValue: customer.customerId,
            cells,
          });
        });
      }

      setTableRows(newTableRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.Content}>
      <div className={styles.HeaderContainer}>
        <H3>Customer List</H3>
      </div>

      <div className={styles.SearchParams}>
        <TextInput
          className={styles.SearchInput}
          type="text"
          label="Email"
          placeholder="customer@email.com"
          field={Field.Email}
          initialValue={options.email}
        />

        <TextInput
          className={styles.SearchInput}
          type="text"
          label="First Name"
          placeholder="John"
          field={Field.FirstName}
          initialValue={options.firstName}
        />

        <TextInput
          className={styles.SearchInput}
          type="text"
          label="Last Name"
          placeholder="Doe"
          field={Field.LastName}
          initialValue={options.lastName}
        />

        <Button
          className={styles.SearchButton}
          size={"xsmall"}
          onClick={updateSearch}
        >
          Update
        </Button>
      </div>

      {loading && <LoadingWrapper loading />}

      {!loading && !error && data && (
        <>
          <AdminTable
            columns={tableColumns}
            rows={tableRows}
            onClickRow={openCustomerPage}
            onChangeSortBy={handleChangeSort}
          />

          <AdminPagination
            itemCount={itemCount}
            perPage={options.perPage}
            pageSelected={options.selectedPage}
            siblingsShown={4}
            onPerPageChange={onChangePerPage}
            onPageSelectedChange={onChangePageSelected}
          />
        </>
      )}
    </div>
  );
};

export default AdminCustomerListView;
