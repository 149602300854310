import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMotorContext } from "~/contexts/MotorProvider";
import { UserContext } from "~/contexts/UserProvider";
import { isMobile } from "react-device-detect";
import { Text, H3 } from "~/components/Typography";
import SubmitButton from "~/components/SubmitButton";
import NoteWithList from "~/components/NoteWithList";
import styles from "./index.module.scss";
import { InsuranceType } from "../../../types";
import MobileUploadRequired from "~/components/MobileUploadRequired";
import { useHomeContext } from "~/contexts/HomeProvider";
import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { Provider } from "~/helpers/constants";

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const AccountCreated = ({ nextPath, insuranceType }: Props) => {
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const userCtx = useContext(UserContext);
  const heapCtx = useHeapContext();
  const history = useHistory();
  const assistantCtx = useAssistantContext();

  useMountEffect(() => {
    assistantCtx.updateAssistant({
      text: undefined,
      isOpen: false,
    });
  });

  const handleClick = () => {
    if (nextPath) {
      heapCtx.track(HeapEventName.ONBOARDING_CREATE_ACCOUNT_SUCCESS, {
        "Policy ID":
          insuranceType === "motor"
            ? motorCtx.policyInfo.data.id
            : homeCtx.policyId,
      });

      // TODO: review why this was here
      // if (homeCtx.occupation === "Student") {
      //   history.push("/BRB/home/documents");
      // } else {
      history.push(nextPath);
      // }
    }
  };

  if (
    !isMobile &&
    userCtx.email &&
    homeCtx.provider !== Provider.IslandHeritage
  ) {
    return (
      <MobileUploadRequired
        title="Great! Your account has been created.
  To complete the process and get your coverage plan, you will need a smartphone."
        email={userCtx.email}
        policyId={
          insuranceType === "motor"
            ? motorCtx.policyInfo.data.id || ""
            : homeCtx.policyId || ""
        }
      />
    );
  }

  return (
    <>
      <Text>{`Welcome to Almi, ${userCtx.name}`}</Text>
      <H3 className={styles.Subtitle} component="h1">
        Your account has been created.
      </H3>

      <div className={styles.InputWrapper}>
        <NoteWithList
          className={styles.Note}
          mainText="To verify your identity please be prepared to upload:"
          list={
            insuranceType === "motor"
              ? [
                  "A Selfie",
                  "Photo of your valid Driver's license (or temporary driver's license slip)",
                  "Photo of your National ID or Passport",
                  "Proof of address (e.g. a utility bill or bank statement with your address)",
                ]
              : [
                  "Photo of your national ID or passport",
                  "Proof of address (e.g. a utility bill or bank statement with your address)",
                ]
          }
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="AccountCreated-SubmitButton"
          className={styles.Button}
          type="button"
          onClick={handleClick}
          autoFocus
        >
          Continue coverage setup
        </SubmitButton>
      </div>
    </>
  );
};

export default AccountCreated;
