import React from "react";
import { gql } from "apollo-boost";
import { useApolloClient } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";

import cx from "classnames";
import styles from "./index.module.scss";

const FetchGqlResponseString = `
  nextPath

  jointInsured {
    title
    maritalStatus
    firstName
    middleInitial
    lastName
    dateOfBirth
    gender
    otherName
    nationality
    pictureIdentification
    occupation
    employer
    mailingAddress
    emailAddress
    mobilePhone
    workPhone
    homePhone
    proofOfResidence
  }

  walls
  floors
  roof
  roofFrame
  roofAnchor
  resistantFeatures
  securityMeasures
  numberOfStoreys

  issues
  liablility
  occupied
  propertyPolicy
  otherPolicy

  propertyType
  constructionYear
  renovationYear
  renovationDetails
  area

  mortgagee
  interestedParties
  relation
`;

const FETCH_ISLAND_HERITAGE_DATA = gql`
  query getIslandHeritageData($policyId: String!) {
    getIslandHeritageData(policyId: $policyId) {${FetchGqlResponseString}}
  }
`;

const SAVE_ISLAND_HERITAGE_DATA = gql`
  mutation saveIslandHeritageData($input: IslandHeritageDataInput!) {
    saveIslandHeritageData(input: $input)
  }
`;

const SUBMIT_ISLAND_HERITAGE_DATA = gql`
  mutation submitIslandHeritageData($policyId: String!) {
    submitIslandHeritageData(policyId: $policyId)
  }
`;

const DATA_PROPS = [
  "walls",
  "floors",
  "roof",
  "roofFrame",
  "roofAnchor",
  "resistantFeatures",
  "securityMeasures",
  "numberOfStoreys",

  "issues",
  "liablility",
  "occupied",
  "propertyPolicy",
  "otherPolicy",

  "propertyType",
  "constructionYear",
  "renovationYear",
  "renovationDetails",
  "area",

  "mortgagee",
  "interestedParties",
  "relation",

  "jointInsured",
];

export const getDefaultJointInsurer = (): IslandHeritageJointInsured => {
  return {
    title: "",
    maritalStatus: "",
    firstName: "",
    middleInitial: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    otherName: "",
    nationality: "",
    pictureIdentification: "",
    occupation: "",
    employer: "",
    mailingAddress: "",
    emailAddress: "",
    mobilePhone: "",
    workPhone: "",
    homePhone: "",
    proofOfResidence: "",
  };
};

export type IslandHeritageJointInsured = {
  title: string;
  maritalStatus: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  otherName: string;
  nationality: string;
  pictureIdentification: string;
  occupation: string;
  employer: string;
  mailingAddress: string;
  emailAddress: string;
  mobilePhone: string;
  workPhone: string;
  homePhone: string;
  proofOfResidence: string;
};

type IslandHeritageData = {
  // Construction
  walls?: string;
  floors?: string;
  roof?: string;
  roofFrame?: string;
  roofAnchor?: string;
  resistantFeatures?: string[];
  securityMeasures?: string[];
  numberOfStoreys?: number;

  // Details
  issues?: string;
  liablility?: string;
  occupied?: string;
  propertyPolicy?: string;
  otherPolicy?: string;

  propertyType?: string;
  constructionYear?: number;
  renovationYear?: number;
  renovationDetails?: string;
  area?: number;

  mortgagee?: string;
  interestedParties?: string;
  relation?: string;

  // Property Values
  improvements?: string;

  jointInsured?: IslandHeritageJointInsured;
};

type IslandHeritageStateType = {
  policyId: string;
  nextPath?: string;
  loaded: boolean;
  data: IslandHeritageData;
};

type IslandHeritageContextType = {
  fetchData: (policyId: string) => void;
  saveData: (data: IslandHeritageData, nextPath: string) => void;
  submitData: (policyId: string) => Promise<boolean>;
} & IslandHeritageStateType;

const initialState: IslandHeritageContextType = {
  policyId: "",
  nextPath: "",
  loaded: false,
  data: {},
  fetchData: () => {
    throw new Error("Island Heritage context has not yet been initialized.");
  },
  saveData: () => {
    throw new Error("Island Heritage context has not yet been initialized.");
  },
  submitData: () => {
    throw new Error("Island Heritage context has not yet been initialized.");
  },
};

const cleanNullValues = (state: IslandHeritageData) => {
  DATA_PROPS.forEach((dataProp) => {
    if (state?.[dataProp] === null) {
      delete state[dataProp];
    }
  });

  return state;
};

export const IslandHeritageContext = React.createContext(initialState);
export const useIslandHeritageContext = () =>
  React.useContext(IslandHeritageContext);

type Props = {
  children: React.ReactNode;
};

export default function IslandHeritageProvider({ children }: Props) {
  const apolloClient = useApolloClient();

  const [state, setState] = React.useState<IslandHeritageStateType>(
    initialState
  );

  const params: {
    policyId: string | undefined;
  } = useParams();

  const fetchData = async (policyId: string) => {
    const { data } = await apolloClient.query({
      query: FETCH_ISLAND_HERITAGE_DATA,
      variables: {
        policyId,
      },
      fetchPolicy: "no-cache",
    });

    setState({
      policyId,
      nextPath: data.getIslandHeritageData.nextPath,
      data: { ...cleanNullValues(data.getIslandHeritageData) },
      loaded: true,
    });
  };

  const saveData = async (data: IslandHeritageData, nextPath: string) => {
    const newState = {
      ...state,
      nextPath,
      data: {
        ...state.data,
        ...data,
      },
    };

    const response = await apolloClient.mutate({
      mutation: SAVE_ISLAND_HERITAGE_DATA,
      variables: {
        input: {
          policyId: newState.policyId,
          ...newState.data,
          nextPath,
        },
      },
    });

    if (response.data.saveIslandHeritageData) {
      setState(newState);
    }

    return true;
  };

  const submitData = async (policyId: string): Promise<boolean> => {
    await apolloClient.mutate({
      mutation: SUBMIT_ISLAND_HERITAGE_DATA,
      variables: {
        policyId,
      },
    });

    return true;
  };

  React.useEffect(() => {
    if (params.policyId) {
      fetchData(params.policyId);
    } else {
      setState(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.policyId]);

  return (
    <IslandHeritageContext.Provider
      value={{
        ...state,
        saveData,
        submitData,
        fetchData,
      }}
    >
      <div
        className={cx({
          [styles.LoadingOffset]: !state.loaded,
        })}
      >
        <LoadingWrapper loading={!state.loaded} />
      </div>

      {state.loaded && children}
    </IslandHeritageContext.Provider>
  );
}
