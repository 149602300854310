import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { InputType } from "~/components/TextInput/index.types";
import { H4, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import {
  getDefaultJointInsurer,
  useIslandHeritageContext,
} from "~/contexts/IslandHeritageProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

import styles from "~/styles/common/textInputPage.module.scss";

enum Field {
  MailingAddress = "JointInsurerContact-MailingAddressField",
  EmailAddress = "JointInsurerContact-EmailAddressField",
  MobilePhone = "JointInsurerContact-MobilePhoneField",
  WorkPhone = "JointInsurerContact-WorkPhoneField",
  HomePhone = "JointInsurerContact-HomePhoneField",
}

type Props = {
  nextPath?: string;
};

export default function JointInsurerContact({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 4,
      totalOfSteps: 24,
      title: "Joint Insurer",
    });
  });

  useUpdateAssistant({
    text: (
      <TextSmall>
        We only require one phone number for your joint insurer.
      </TextSmall>
    ),
  });

  const [saving, setSaving] = React.useState<boolean>(false);

  const { value: mailingAddressValue } = useCustomFieldState(
    Field.MailingAddress
  );
  const { value: emailAddressValue } = useCustomFieldState(Field.EmailAddress);
  const { value: mobilePhoneValue } = useCustomFieldState(Field.MobilePhone);
  const { value: workPhoneValue } = useCustomFieldState(Field.WorkPhone);
  const { value: homePhoneValue } = useCustomFieldState(Field.HomePhone);

  const fields: Array<{
    field: Field;
    label: string;
    placeholder: string;
    type: InputType;
    initialValue: string | undefined;
  }> = [
    {
      field: Field.MailingAddress,
      label: "Mailing Address",
      placeholder: "Mailing Address",
      type: "text",
      initialValue: ihCtx.data?.jointInsured?.mailingAddress,
    },
    {
      field: Field.EmailAddress,
      label: "Email Address",
      placeholder: "Email Address",
      type: "email",
      initialValue: ihCtx.data?.jointInsured?.emailAddress,
    },
    {
      field: Field.MobilePhone,
      label: "Mobile Phone",
      placeholder: "Mobile Phone",
      type: "phone",
      initialValue: ihCtx.data?.jointInsured?.mobilePhone,
    },
    {
      field: Field.WorkPhone,
      label: "Work Phone",
      placeholder: "Work Phone",
      type: "phone",
      initialValue: ihCtx.data?.jointInsured?.workPhone,
    },
    {
      field: Field.HomePhone,
      label: "Home Phone",
      placeholder: "Home Phone",
      type: "phone",
      initialValue: ihCtx.data?.jointInsured?.homePhone,
    },
  ];

  const handleNextClick = async () => {
    setSaving(true);

    await ihCtx.saveData(
      {
        jointInsured: {
          ...(!ihCtx.data.jointInsured
            ? getDefaultJointInsurer()
            : ihCtx.data.jointInsured),
          mailingAddress: mailingAddressValue.toString(),
          emailAddress: emailAddressValue.toString(),
          mobilePhone: mobilePhoneValue.toString(),
          workPhone: workPhoneValue.toString(),
          homePhone: homePhoneValue.toString(),
        },
      },
      nextPath ?? ""
    );

    history.push(nextPath ?? "");
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H4>Please provide contact information for your joint insurer</H4>

          {fields.map((field) => {
            return (
              <div key={field.field} className={styles.TextInputWrapperThin}>
                <TextInput
                  field={field.field}
                  placeholder={field.placeholder}
                  type={field.type}
                  label={field.label}
                  keepState
                  initialValue={field.initialValue ?? undefined}
                />
              </div>
            );
          })}

          <div className={styles.ButtonWrapper}>
            <SubmitButton
              id="JointInsurerName-SubmitButton"
              className={styles.ButtonNext}
              onClick={handleNextClick}
              disabled={
                !mailingAddressValue ||
                !emailAddressValue ||
                !(homePhoneValue || mobilePhoneValue || workPhoneValue)
              }
            >
              Next
            </SubmitButton>
          </div>
        </>
      )}
    </>
  );
}
