import { RadioGroup } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioSelector from "~/components/RadioSelector";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { InputType } from "~/components/TextInput/index.types";
import { H4, H5 } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import {
  getDefaultJointInsurer,
  useIslandHeritageContext,
} from "~/contexts/IslandHeritageProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

import styles from "~/styles/common/textInputPage.module.scss";

enum Field {
  Honorific = "JointInsurerMisc-HonorificField",
  Married = "JointInsurerMisc-MarriedField",
  DateOfBirth = "JointInsurerMisc-DateOfBirthField",
  Gender = "JointInsurerMisc-GenderField",
  Nationality = "JointInsurerMisc-NationalityField",
}

type Props = {
  nextPath?: string;
};

export default function JointInsurerMisc({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const ihCtx = useIslandHeritageContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 3,
      totalOfSteps: 24,
      title: "Joint Insurer",
    });
  });

  const [saving, setSaving] = React.useState<boolean>(false);

  const [honorific, setHonorific] = React.useState<string>(() => {
    const currentHonorific = ihCtx.data?.jointInsured?.title;

    if (!currentHonorific) {
      return "";
    }

    if (["mr", "miss", "mrs", "ms"].includes(currentHonorific ?? "")) {
      return currentHonorific;
    }

    return "other";
  });
  const { value: honorificOtherValue } = useCustomFieldState(Field.Honorific);
  const [gender, setGender] = React.useState<string>(
    ihCtx.data?.jointInsured?.gender ?? ""
  );
  const [married, setMarried] = React.useState<string>(
    ihCtx.data?.jointInsured?.maritalStatus ?? ""
  );
  const { value: dateOfBirthValue } = useCustomFieldState(Field.DateOfBirth);
  const { value: nationalityValue } = useCustomFieldState(Field.Nationality);

  const fields: Array<{
    field: Field;
    label: string;
    placeholder: string;
    type: InputType;
    initialValue: string | undefined;
  }> = [
    {
      field: Field.DateOfBirth,
      label: "Date of Birth",
      placeholder: "DD/MM/YYYY",
      type: "date",
      initialValue: ihCtx.data?.jointInsured?.dateOfBirth,
    },
    {
      field: Field.Nationality,
      label: "Nationality",
      placeholder: "Nationality",
      type: "text",
      initialValue: ihCtx.data?.jointInsured?.nationality,
    },
  ];

  const handleNextClick = async () => {
    setSaving(true);

    await ihCtx.saveData(
      {
        jointInsured: {
          ...(!ihCtx.data.jointInsured
            ? getDefaultJointInsurer()
            : ihCtx.data.jointInsured),
          title:
            honorific !== "other" ? honorific : honorificOtherValue.toString(),
          maritalStatus: married ? "married" : "single",
          dateOfBirth: dateOfBirthValue.toString(),
          gender,
          nationality: nationalityValue.toString(),
        },
      },
      nextPath ?? ""
    );

    history.push(nextPath ?? "");
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H4>
            Please provide some additional information about your joint insurer
          </H4>

          <legend>
            <H5>Title or Honorific</H5>
          </legend>

          <div className={styles.RadioContentThin}>
            <RadioGroup field={Field.Honorific}>
              <RadioSelector
                field={Field.Honorific}
                value="mr"
                checked={honorific === "mr"}
                onChange={() => {
                  setHonorific("mr");
                }}
              >
                Mr
              </RadioSelector>

              <RadioSelector
                field={Field.Honorific}
                value="mrs"
                checked={honorific === "mrs"}
                onChange={() => {
                  setHonorific("mrs");
                }}
              >
                Mrs
              </RadioSelector>

              <RadioSelector
                field={Field.Honorific}
                value="miss"
                checked={honorific === "miss"}
                onChange={() => {
                  setHonorific("miss");
                }}
              >
                Miss
              </RadioSelector>

              <RadioSelector
                field={Field.Honorific}
                value="ms"
                checked={honorific === "ms"}
                onChange={() => {
                  setHonorific("ms");
                }}
              >
                Ms
              </RadioSelector>

              <RadioSelector
                field={Field.Honorific}
                value="other"
                checked={honorific === "other"}
                onChange={() => {
                  setHonorific("other");
                }}
              >
                Other
              </RadioSelector>
            </RadioGroup>
          </div>

          {honorific === "other" && (
            <TextInput
              placeholder="Other"
              field={Field.Honorific}
              type="text"
              initialValue={ihCtx.data?.jointInsured?.title}
            />
          )}

          <legend>
            <H5>Marital Status</H5>
          </legend>

          <div className={styles.RadioContentThin}>
            <RadioGroup field={Field.Married}>
              <RadioSelector
                field={Field.Married}
                value="single"
                checked={married === "single"}
                onChange={() => {
                  setMarried("single");
                }}
              >
                Single
              </RadioSelector>

              <RadioSelector
                field={Field.Married}
                value="married"
                checked={married === "married"}
                onChange={() => {
                  setMarried("married");
                }}
              >
                Married
              </RadioSelector>
            </RadioGroup>
          </div>

          <legend>
            <H5>Gender</H5>
          </legend>

          <div className={styles.RadioContentThin}>
            <RadioGroup field={Field.Gender}>
              <RadioSelector
                field={Field.Gender}
                value="male"
                checked={gender === "male"}
                onChange={() => {
                  setGender("male");
                }}
              >
                Male
              </RadioSelector>

              <RadioSelector
                field={Field.Gender}
                value="female"
                checked={gender === "female"}
                onChange={() => {
                  setGender("female");
                }}
              >
                Female
              </RadioSelector>
            </RadioGroup>
          </div>

          {fields.map((field) => {
            return (
              <div key={field.field} className={styles.TextInputWrapperThin}>
                <TextInput
                  field={field.field}
                  placeholder={field.placeholder}
                  type={field.type}
                  label={field.label}
                  keepState
                  initialValue={field.initialValue ?? undefined}
                />
              </div>
            );
          })}

          <div className={styles.ButtonWrapper}>
            <SubmitButton
              id="JointInsurerName-SubmitButton"
              className={styles.ButtonNext}
              onClick={handleNextClick}
              disabled={
                !gender ||
                !honorific ||
                !dateOfBirthValue ||
                !nationalityValue ||
                !married
              }
            >
              Next
            </SubmitButton>
          </div>
        </>
      )}
    </>
  );
}
