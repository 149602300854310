import React from "react";
import Icon from "~/components/Icon";
import { TextSmall } from "~/components/Typography";
import { Quote } from "../../../../../types";
import classnames from "classnames";

import styles from "./index.module.scss";

type Item = {
  id: string;
  title: string;
  active: boolean;
};

const islandHeritageStandardItems: Item[] = [
  {
    id: "home-doubled-limits",
    title: "Doubled Limits On Most Covers",
    active: false,
  },
  {
    id: "home-decreased-deductibles",
    title: "Deductible Decreases",
    active: false,
  },
];

const islandHeritagePlusItems: Item[] = [
  {
    id: "home-doubled-limits",
    title: "Doubled Limits On Most Covers",
    active: true,
  },
  {
    id: "home-decreased-deductibles",
    title: "Deductible Decreases",
    active: true,
  },
];

const CoveredList = ({ quote }: { quote: Quote }) => {
  const type = quote.typeOfCover;

  console.log("Type: ", type);

  const renderItem = (item: Item) => {
    return (
      <TextSmall
        key={item.id}
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: !item.active,
        })}
      >
        <Icon
          name={!item.active ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={!item.active ? "#91A29C" : "#31AD63"}
        />
        {item.title}
      </TextSmall>
    );
  };

  return (
    <>
      {(type === "IH_PLUS"
        ? islandHeritagePlusItems
        : islandHeritageStandardItems
      ).map((item) => renderItem(item))}
    </>
  );
};

export default CoveredList;
