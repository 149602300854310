import React from "react";
import { useHistory } from "react-router";
import { Bold, TextSmall } from "~/components/Typography";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import BooleanAndDetails from "~/screens/Home/PageShells/BooleanAndDetails";

type Props = {
  nextPath?: string;
};

export default function IHDOccupied({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        Say <Bold>Yes</Bold> if the home will not be left vacant for more than
        30 days
      </TextSmall>
    ),
  });

  const handleChange = async (value: string | undefined) => {
    await ihCtx.saveData(
      {
        occupied: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <BooleanAndDetails
      onChange={handleChange}
      mainText="Will the property be occupied continually by you or others with
      your permission?"
      detailText="Please provide full details below"
      fieldName="ihdOccupied"
      initialValue={ihCtx.data?.occupied}
      showDetailOnFalse
      header={{
        currentStep: 20,
        totalSteps: 24,
        title: "Property Details",
      }}
    />
  );
}
