import React from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import AdminPagination from "~/components/AdminPagination";
import AdminTable, {
  AdminTableColumn,
  AdminTableRow,
} from "~/components/AdminTable";
import tableStyles from "../tableStyles.module.scss";
import { Bold, H3, Text } from "~/components/Typography";
import Button from "~/components/Button";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import Status from "~/components/Status";
import { ADMIN_DASHBOARD_URL } from "~/routes/index.constant";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import { useFieldState } from "informed";
import { cleanInputDate } from "~/helpers/validators";
import Checkbox from "~/components/Checkbox";

enum Field {
  CoverageStartDateRangeStart = "AdminPolicyListView-CoverageStartDateRangeStart",
  CoverageStartDateRangeEnd = "AdminPolicyListView-CoverageStartDateRangeEnd",
  PurchaseDateRangeStart = "AdminPolicyListView-PurchaseDateRangeStart",
  PurchaseDateRangeEnd = "AdminPolicyListView-PurchaseDateRangeEnd",
  LatePayments = "AdminpolicyListView-LatePayments",
}

type ScheduledPayment = {
  paymentDate: Date;
  amount: string;
  status: string;
  lastModified: Date;
  paymentRecordId: string;
};

type Policy = {
  policyId: string;
  webstarId: string;
  type: string;
  status: string;
  clientName: string;
  createdDate: Date;
  startDate: Date;
  endDate: Date;
  purchaseDate?: string;
  annualPremium: string;
  scheduledPayments: ScheduledPayment[];
};

type GetPolicyList = {
  adminPolicyListView: {
    policies: Policy[];
    count?: number;
  };
};

const INSTALMENTS_DISPLAYED = 12;

const GET_POLICY_LIST = gql`
  query adminPolicyListView(
    $coverageStartDateRangeStart: String
    $coverageStartDateRangeEnd: String
    $purchaseDateRangeStart: String
    $purchaseDateRangeEnd: String
    $sortField: String
    $sortDesc: Boolean
    $page: Float
    $perPage: Float
    $getDocumentCount: Boolean
    $latePaymentsView: Boolean
  ) {
    adminPolicyListView(
      coverageStartDateRangeStart: $coverageStartDateRangeStart
      coverageStartDateRangeEnd: $coverageStartDateRangeEnd
      purchaseDateRangeStart: $purchaseDateRangeStart
      purchaseDateRangeEnd: $purchaseDateRangeEnd
      sortField: $sortField
      sortDesc: $sortDesc
      page: $page
      perPage: $perPage
      getDocumentCount: $getDocumentCount
      latePaymentsView: $latePaymentsView
    ) {
      policies {
        policyId
        webstarId
        type
        status
        clientName
        createdDate
        startDate
        endDate
        purchaseDate
        annualPremium
        scheduledPayments {
          paymentDate
          amount
          status
          lastModified
          paymentRecordId
        }
      }
      count
    }
  }
`;

const AdminPolicyListView = () => {
  const location = useLocation();

  const coverageStartDateRangeStartState = useFieldState(
    Field.CoverageStartDateRangeStart
  );

  const coverageStartDateRangeEndState = useFieldState(
    Field.CoverageStartDateRangeEnd
  );

  const purchaseDateRangeStartState = useFieldState(
    Field.PurchaseDateRangeStart
  );

  const purchaseDateRangeEndState = useFieldState(Field.PurchaseDateRangeEnd);

  const getParam = (param: string) => {
    return new URLSearchParams(location.search).get(param) ?? undefined;
  };

  const convertBackendDate = (date: string) => {
    const parts = date.split("/");
    // eslint-disable-next-line no-magic-numbers
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  const [options, setOptions] = React.useState<{
    coverageStartDateRangeStart: string | undefined;
    coverageStartDateRangeEnd: string | undefined;
    purchaseDateRangeStart: string | undefined;
    purchaseDateRangeEnd: string | undefined;
    sortField: string | undefined;
    sortDesc: boolean;
    selectedPage: number;
    perPage: number;
    latePaymentsView: boolean;
    itemCountIncrement: number;
  }>({
    coverageStartDateRangeStart: getParam("coverageStartDateRangeStart"),
    coverageStartDateRangeEnd: getParam("coverageStartDateRangeEnd"),
    purchaseDateRangeStart: getParam("purchaseDateRangeStart"),
    purchaseDateRangeEnd: getParam("purchaseDateRangeEnd"),
    sortField: getParam("sortField") ?? "createdDate",
    sortDesc: getParam("sortDesc") === "false" ? false : true,
    selectedPage: 1,
    perPage: 5,
    latePaymentsView: getParam("latePaymentsView") === "true" ? true : false,
    itemCountIncrement: 1,
  });

  const [tableColumns, setTableColumns] = React.useState<AdminTableColumn[]>(
    []
  );

  const [tableRows, setTableRows] = React.useState<AdminTableRow[]>([]);
  const [
    lastItemCountIncrement,
    setLastItemCountIncrement,
  ] = React.useState<number>(0);
  const [itemCount, setItemCount] = React.useState<number>(0);

  const history = useHistory();

  const getPaymentCellClassName = (payment: ScheduledPayment) => {
    switch (payment.status) {
      case "paid":
        return tableStyles.GreenCell;
      case "pending":
        return tableStyles.GrayCell;
      case "late":
        return tableStyles.RedCell;
      case "cancelled":
        return tableStyles.GrayCell;
      default:
        return "";
    }
  };

  const openPolicyPage = (policyId: string) => {
    history.push(`${ADMIN_DASHBOARD_URL}/policy/${policyId}`);
  };

  const onChangePerPage = (newPerPage: number) => {
    setOptions((options) => ({
      ...options,
      perPage: newPerPage,
      selectedPage: 1,
    }));
  };

  const onChangePageSelected = (newPageSelected: number) => {
    setOptions((options) => ({
      ...options,
      selectedPage: newPageSelected,
    }));
  };

  const onChangeLatePaymentsView = () => {
    setOptions((options) => ({
      ...options,
      latePaymentsView: !options.latePaymentsView,
      itemCountIncrement: (options.itemCountIncrement += 1),
      selectedPage: 1,
    }));
  };

  const getDateString = (date: Date) => {
    return date.toLocaleDateString();
  };

  const handleChangeSort = (sort: string) => {
    if (sort === options.sortField) {
      setOptions((options) => ({
        ...options,
        sortDesc: !options.sortDesc,
        selectedPage: 1,
      }));
    } else {
      setOptions((options) => ({
        ...options,
        sortField: sort,
        sortDesc: false,
        selectedPage: 1,
      }));
    }
  };

  const updateSearch = () => {
    setOptions((options) => ({
      ...options,
      coverageStartDateRangeStart: coverageStartDateRangeStartState.value
        ? cleanInputDate(String(coverageStartDateRangeStartState.value))
        : undefined,
      coverageStartDateRangeEnd: coverageStartDateRangeEndState.value
        ? cleanInputDate(String(coverageStartDateRangeEndState.value))
        : undefined,
      purchaseDateRangeStart: purchaseDateRangeStartState.value
        ? cleanInputDate(String(purchaseDateRangeStartState.value))
        : undefined,
      purchaseDateRangeEnd: purchaseDateRangeEndState.value
        ? cleanInputDate(String(purchaseDateRangeEndState.value))
        : undefined,
      itemCountIncrement: (options.itemCountIncrement += 1),
      selectedPage: 1,
    }));
  };

  const [getPolicyList, { loading, error, data }] = useLazyQuery<GetPolicyList>(
    GET_POLICY_LIST,
    {
      fetchPolicy: "no-cache",
    }
  );

  React.useEffect(() => {
    const newTableColumns: AdminTableColumn[] = [
      {
        displayName: "ROW #",
      },
      {
        displayName: "POLICY ID",
      },
      {
        displayName: "WEBSTAR ID",
      },
      {
        displayName: "POLICY TYPE",
      },
      {
        displayName: "CLIENT NAME",
      },
      {
        displayName: "STATUS",
      },
      {
        displayName: "ANNUAL PREMIUM",
      },
      {
        displayName: "CREATED DATE",
        sortData: {
          sortable: true,
          sortBy: options.sortField === "createdDate",
          sortDesc: !!options.sortDesc,
          sortField: "createdDate",
        },
      },
      {
        displayName: "PURCHASE DATE",
        sortData: {
          sortable: true,
          sortBy: options.sortField === "purchaseDate",
          sortDesc: !!options.sortDesc,
          sortField: "purchaseDate",
        },
      },
      {
        displayName: "START DATE",
        sortData: {
          sortable: true,
          sortBy: options.sortField === "startDate",
          sortDesc: !!options.sortDesc,
          sortField: "startDate",
        },
      },
      {
        displayName: "END DATE",
      },
    ];

    for (let i = 1; i < INSTALMENTS_DISPLAYED; i++) {
      newTableColumns.push({
        displayName: `INSTALMENT ${i}`,
      });
    }

    setTableColumns(newTableColumns);

    getPolicyList({
      variables: {
        coverageStartDateRangeStart: options.coverageStartDateRangeStart
          ? convertBackendDate(options.coverageStartDateRangeStart)
          : undefined,
        coverageStartDateRangeEnd: options.coverageStartDateRangeEnd
          ? convertBackendDate(options.coverageStartDateRangeEnd)
          : undefined,
        purchaseDateRangeStart: options.purchaseDateRangeStart
          ? convertBackendDate(options.purchaseDateRangeStart)
          : undefined,
        purchaseDateRangeEnd: options.purchaseDateRangeEnd
          ? convertBackendDate(options.purchaseDateRangeEnd)
          : undefined,
        sortField: options.sortField,
        sortDesc: options.sortDesc,
        page: options.selectedPage,
        perPage: options.perPage,
        getDocumentCount: options.itemCountIncrement > lastItemCountIncrement,
        latePaymentsView: options.latePaymentsView,
      },
    });

    if (options.itemCountIncrement > lastItemCountIncrement) {
      setLastItemCountIncrement(options.itemCountIncrement);
    }

    const params = {
      ...(options.coverageStartDateRangeStart && {
        coverageStartDateRangeStart: options.coverageStartDateRangeStart,
      }),
      ...(options.coverageStartDateRangeEnd && {
        coverageStartDateRangeEnd: options.coverageStartDateRangeEnd,
      }),
      ...(options.purchaseDateRangeStart && {
        purchaseDateRangeStart: options.purchaseDateRangeStart,
      }),
      ...(options.purchaseDateRangeEnd && {
        purchaseDateRangeEnd: options.purchaseDateRangeEnd,
      }),
      sortField: options.sortField,
      sortDesc: options.sortDesc ? "true" : "false",
      ...(options.latePaymentsView && {
        latePaymentsView: "true",
      }),
    };

    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`;

    window.history.replaceState({ path: newUrl }, "", newUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (data) {
      if (
        data.adminPolicyListView.count !== undefined &&
        data.adminPolicyListView.count !== null
      ) {
        setItemCount(data.adminPolicyListView.count);
      }

      const newTableRows: AdminTableRow[] = [];

      if (data.adminPolicyListView.policies) {
        data.adminPolicyListView.policies.reverse().forEach((policy, index) => {
          const cells: React.ReactElement[] = [
            <Text key={`cell-row-${index}`}>
              {String(index + 1 + (options.selectedPage - 1) * options.perPage)}
            </Text>,
            <Text key={`cell-policyId-${index}`}>{policy.policyId}</Text>,
            <Text key={`cell-webstarId-${index}`}>{policy.webstarId}</Text>,
            <Text key={`cell-type-${index}`}>{policy.type}</Text>,
            <Text key={`cell-client-${index}`}>{policy.clientName}</Text>,
            <Status key={`cell-status-${index}`} status={policy.status} />,
            <Bold key={`cell-premium-${index}`}>${policy.annualPremium}</Bold>,
            <Text key={`cell-createdDate-${index}`}>
              {getDateString(new Date(policy.createdDate))}
            </Text>,
            <Text key={`cell-purchaseDate-${index}`}>
              {policy.purchaseDate
                ? getDateString(new Date(policy.purchaseDate))
                : ""}
            </Text>,
            <Text key={`cell-startDate-${index}`}>
              {getDateString(new Date(policy.startDate))}
            </Text>,
            <Text key={`cell-endDate-${index}`}>
              {getDateString(new Date(policy.endDate))}
            </Text>,
          ];

          let paymentsListed = 0;

          policy.scheduledPayments.forEach((payment) => {
            cells.push(
              <Text className={getPaymentCellClassName(payment)}>
                <Bold>${payment.amount}</Bold>
                <br />
                {getDateString(new Date(payment.paymentDate))}
                <br />
                {payment.status}
              </Text>
            );

            paymentsListed++;
          });

          //Fill out remaining cells.
          for (let i = paymentsListed; i < INSTALMENTS_DISPLAYED - 1; i++) {
            cells.push(<></>);
          }

          newTableRows.push({
            onClickRowValue: policy.policyId,
            cells,
          });
        });
      }

      setTableRows(newTableRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <div className={styles.HeaderContainer}>
        <H3>Portfolio View</H3>

        <div className={styles.SearchParams}>
          <TextInput
            label="Coverage Starts After"
            field={Field.CoverageStartDateRangeStart}
            placeholder="DD/MM/YYYY"
            type="date"
            maintainCursor
            validateOnChange
            keepState
            initialValue={options.coverageStartDateRangeStart}
          />

          <TextInput
            label="Coverage Starts Before"
            field={Field.CoverageStartDateRangeEnd}
            placeholder="DD/MM/YYYY"
            type="date"
            maintainCursor
            validateOnChange
            keepState
            initialValue={options.coverageStartDateRangeEnd}
          />
        </div>

        <div className={styles.SearchParams}>
          <TextInput
            label="Purchased After"
            field={Field.PurchaseDateRangeStart}
            placeholder="DD/MM/YYYY"
            type="date"
            maintainCursor
            validateOnChange
            keepState
            initialValue={options.purchaseDateRangeStart}
          />

          <TextInput
            label="Purchased Before"
            field={Field.PurchaseDateRangeEnd}
            placeholder="DD/MM/YYYY"
            type="date"
            maintainCursor
            validateOnChange
            keepState
            initialValue={options.purchaseDateRangeEnd}
          />
        </div>

        <Checkbox
          className={styles.latePayments}
          field={Field.LatePayments}
          label="Show Late Payments Only"
          initialValue={options.latePaymentsView}
          onChange={onChangeLatePaymentsView}
        />

        <Button
          className={styles.SearchButton}
          size={"xsmall"}
          onClick={updateSearch}
        >
          Update
        </Button>
      </div>

      {loading && <LoadingWrapper loading />}

      {!loading && !error && data && (
        <>
          <AdminTable
            columns={tableColumns}
            rows={tableRows}
            onClickRow={openPolicyPage}
            onChangeSortBy={handleChangeSort}
          />

          <AdminPagination
            itemCount={itemCount}
            perPage={options.perPage}
            pageSelected={options.selectedPage}
            siblingsShown={4}
            onPerPageChange={onChangePerPage}
            onPageSelectedChange={onChangePageSelected}
          />
        </>
      )}
    </div>
  );
};

export default AdminPolicyListView;
