import React from "react";
import { useHistory } from "react-router";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import BooleanAndDetails from "~/screens/Home/PageShells/BooleanAndDetails";

type Props = {
  nextPath?: string;
};

export default function IHDPropertyPolicy({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string | undefined) => {
    await ihCtx.saveData(
      {
        propertyPolicy: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <BooleanAndDetails
      onChange={handleChange}
      mainText="Do you have any other current insurance policies with Island
      Heritage Insurance Company, Ltd.?"
      detailText="Please provide the policy number(s)"
      fieldName="ihdPropertyPolicy"
      initialValue={ihCtx.data?.propertyPolicy}
      header={{
        currentStep: 22,
        totalSteps: 24,
        title: "Property Details",
      }}
    />
  );
}
