import { Form } from "informed";
import React from "react";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import Footer from "~/components/Footer";
import MultiStepForm from "~/components/MultiStepForm";
import AssistantProvider from "~/contexts/AlmiAssistantProvider";
import HeaderProvider from "~/contexts/HeaderProvider";
import IslandHeritageProvider from "~/contexts/IslandHeritageProvider";
import { ElementStepType } from "~/helpers/flow";
import CosntructionFloors from "~/screens/Home/Exclusives/IslandHeritage/Construction/Floors";
import NumberOfStoreys from "~/screens/Home/Exclusives/IslandHeritage/Construction/NumberOfStoreys";
import ResistantFeatures from "~/screens/Home/Exclusives/IslandHeritage/Construction/ResistantFeatures";
import CosntructionRoof from "~/screens/Home/Exclusives/IslandHeritage/Construction/Roof";
import CosntructionRoofAnchor from "~/screens/Home/Exclusives/IslandHeritage/Construction/RoofAnchor";
import CosntructionRoofFrame from "~/screens/Home/Exclusives/IslandHeritage/Construction/RoofFrame";
import SecurityMeasures from "~/screens/Home/Exclusives/IslandHeritage/Construction/SecurityMeasures";
import CosntructionWalls from "~/screens/Home/Exclusives/IslandHeritage/Construction/Walls";
import HasJointInsurer from "~/screens/Home/Exclusives/IslandHeritage/JointInsurer/HasJointInsurer";
import JointInsurerContact from "~/screens/Home/Exclusives/IslandHeritage/JointInsurer/JointInsurerContact";
import JointInsurerEmployer from "~/screens/Home/Exclusives/IslandHeritage/JointInsurer/JointInsurerEmployer";
import JointInsurerMisc from "~/screens/Home/Exclusives/IslandHeritage/JointInsurer/JointInsurerMisc";
import JointInsurerName from "~/screens/Home/Exclusives/IslandHeritage/JointInsurer/JointInsurerName";
import FloorArea from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/FloorArea";
import IHDIssues from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/Issues";
import LastRenovation from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/LastRenovation";
import IHDLiability from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/Liability";
import IHDMortgagee from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/Mortgagee";
import IHDOccupied from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/Occupied";
import IHDOtherPolicy from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/OtherPolicy";
import IHDPayees from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/Payees";
import PropertyDescription from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/PropertyDescription";
import IHDPropertyPolicy from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/PropertyPolicy";
import YearOfConstruction from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/YearOfConstruction";
import DataCollectionComplete from "~/screens/Home/Shared/DataCollectionComplete";
import DataCollectionRedirect from "~/screens/Home/Shared/DataCollectionRedirect";

const BASE_ISLAND_HERITAGE_URL = "/island-heritage/home/:policyId/";

const STEPS: ElementStepType[] = [
  {
    path: `redirect`,
    element: <DataCollectionRedirect />,
  },

  {
    path: `has-joint-insurer`,
    element: <HasJointInsurer />,
    hideBackButton: true,
  },
  {
    path: `joint-insurer-name`,
    element: <JointInsurerName />,
  },
  {
    path: `joint-insurer-misc`,
    element: <JointInsurerMisc />,
  },
  {
    path: `joint-insurer-contact`,
    element: <JointInsurerContact />,
  },
  {
    path: `joint-insurer-employer`,
    element: <JointInsurerEmployer />,
  },

  {
    path: `construction-floors`,
    element: <CosntructionFloors />,
  },
  {
    path: `construction-walls`,
    element: <CosntructionWalls />,
  },
  {
    path: `construction-roof`,
    element: <CosntructionRoof />,
  },
  {
    path: `construction-roof-anchor`,
    element: <CosntructionRoofAnchor />,
  },
  {
    path: `construction-roof-frame`,
    element: <CosntructionRoofFrame />,
  },
  {
    path: `construction-resistant-features`,
    element: <ResistantFeatures />,
  },
  {
    path: `construction-security-measures`,
    element: <SecurityMeasures />,
  },
  {
    path: `construction-storeys`,
    element: <NumberOfStoreys />,
  },

  {
    path: `details-property-description`,
    element: <PropertyDescription />,
  },
  {
    path: `details-year-of-construction`,
    element: <YearOfConstruction />,
  },
  {
    path: `details-last-renovation`,
    element: <LastRenovation />,
  },
  {
    path: `details-floor-area`,
    element: <FloorArea />,
  },

  {
    path: `details-prior-issues`,
    element: <IHDIssues />,
  },
  {
    path: `details-prior-liability`,
    element: <IHDLiability />,
  },
  {
    path: `details-continual-occupancy`,
    element: <IHDOccupied />,
  },
  {
    path: `details-other-policy`,
    element: <IHDOtherPolicy />,
  },
  {
    path: `details-property-policy`,
    element: <IHDPropertyPolicy />,
  },

  {
    path: `details-mortagee`,
    element: <IHDMortgagee />,
  },
  {
    path: `details-interested-parties`,
    element: <IHDPayees />,
  },
  /*
  {
    path: `property-improvements`,
    element: <IHImprovements />,
  },
  */
  {
    path: `complete`,
    element: <DataCollectionComplete />,
    nextPathOverride: "/BRB/home/documents",
  },
].map((step) => {
  return {
    ...step,
    path: `${BASE_ISLAND_HERITAGE_URL}${step.path}`,
    isAuthorized: true,
  };
});

const IslandHeritageHome = () => {
  const stepElements: React.ReactElement[] = React.useMemo(() => {
    const elements: React.ReactElement[] = STEPS.map((step) => {
      return step.isAuthorized && step.element ? (
        <AuthorizedRoute passOnProps>{step.element}</AuthorizedRoute>
      ) : (
        step.element
      );
    });

    return elements;
  }, []);

  return (
    <IslandHeritageProvider>
      <HeaderProvider>
        <AssistantProvider>
          <Form>
            <MultiStepForm steps={STEPS}>{stepElements}</MultiStepForm>
          </Form>

          <Footer />
        </AssistantProvider>
      </HeaderProvider>
    </IslandHeritageProvider>
  );
};

export default IslandHeritageHome;
