import React from "react";
import { useHistory } from "react-router-dom";
import { TextSmall } from "~/components/Typography";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import MultiSelectAndOther from "~/screens/Home/PageShells/MultiSelectAndOther";

type Props = {
  nextPath?: string;
};

export default function SecurityMeasures({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        Does your home have any features to prevent theft or fire damage?
      </TextSmall>
    ),
  });

  const handleChange = async (values: string[]) => {
    await ihCtx.saveData(
      {
        securityMeasures: values,
      },
      nextPath ?? ""
    );

    history.push(nextPath ?? "");
  };

  return (
    <MultiSelectAndOther
      onChange={handleChange}
      initialValues={ihCtx.data?.securityMeasures || []}
      text="Does your home have any security measures?"
      fieldName="securityMeasures"
      buttonOptions={[
        {
          label: "Burglar Alarm",
          value: "burglar-alarm",
        },
        {
          label: "Fully monitored Burglar Alarm System",
          value: "monitored-burglar-alarm",
        },
        {
          label: "Fire Alarm",
          value: "fire-alarm",
        },
      ]}
      header={{
        currentStep: 12,
        totalSteps: 24,
        title: "Construction",
      }}
    />
  );
}
