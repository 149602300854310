import React from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import AdminPagination from "~/components/AdminPagination";
import AdminTable, {
  AdminTableColumn,
  AdminTableRow,
} from "~/components/AdminTable";
import { Text } from "~/components/Typography";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import Status from "~/components/Status";
import { ADMIN_DASHBOARD_URL } from "~/routes/index.constant";

type Policy = {
  policyId: string;
  webstarId: string;
  type: string;
  status: string;
  createdDate: Date;
  startDate: Date;
  purchaseDate: Date;
};

type GetPolicyList = {
  adminPolicyListView: {
    policies: Policy[];
    count?: number;
  };
};

const GET_POLICY_LIST = gql`
  query adminPolicyListView(
    $holderId: String
    $sortField: String
    $sortDesc: Boolean
    $page: Float
    $perPage: Float
    $getDocumentCount: Boolean
  ) {
    adminPolicyListView(
      holderId: $holderId
      sortField: $sortField
      sortDesc: $sortDesc
      page: $page
      perPage: $perPage
      getDocumentCount: $getDocumentCount
    ) {
      policies {
        policyId
        webstarId
        type
        createdDate
        startDate
        purchaseDate
        status
      }
      count
    }
  }
`;

type Props = {
  customerId?: string;
};

const AdminCustomerPoliciesTable = ({ customerId }: Props) => {
  const [tableColumns, setTableColumns] = React.useState<AdminTableColumn[]>(
    []
  );

  const [tableRows, setTableRows] = React.useState<AdminTableRow[]>([]);
  const [
    lastItemCountIncrement,
    setLastItemCountIncrement,
  ] = React.useState<number>(0);
  const [itemCount, setItemCount] = React.useState<number>(0);

  const [options, setOptions] = React.useState<{
    sortField: string | undefined;
    sortDesc: boolean;
    selectedPage: number;
    perPage: number;
    itemCountIncrement: number;
  }>({
    sortField: "createdDate",
    sortDesc: true,
    selectedPage: 1,
    perPage: 5,
    itemCountIncrement: 1,
  });

  const history = useHistory();

  const [getPolicyList, { loading, data }] = useLazyQuery<GetPolicyList>(
    GET_POLICY_LIST,
    {
      fetchPolicy: "no-cache",
    }
  );

  const handleChangeSort = (sort: string) => {
    if (sort === options.sortField) {
      setOptions((options) => ({
        ...options,
        sortDesc: !options.sortDesc,
      }));
    } else {
      setOptions((options) => ({
        ...options,
        sortField: sort,
        sortDesc: false,
      }));
    }
  };

  const onChangePerPage = (newPerPage: number) => {
    setOptions((options) => ({
      ...options,
      perPage: newPerPage,
      selectedPage: 1,
    }));
  };

  const onChangePageSelected = (newPageSelected: number) => {
    setOptions((options) => ({
      ...options,
      selectedPage: newPageSelected,
    }));
  };

  const openPolicyPage = (policyId: string) => {
    history.push(`${ADMIN_DASHBOARD_URL}/policy/${policyId}`);
  };

  const getDateDisplay = (date: Date) => {
    return new Date(date).toLocaleDateString();
  };

  React.useEffect(() => {
    const newTableColumns: AdminTableColumn[] = [
      {
        displayName: "POLICY TYPE",
      },
      {
        displayName: "POLICY ID",
      },
      {
        displayName: "WEBSTAR ID",
      },
      {
        displayName: "CREATED DATE",
        sortData: {
          sortable: true,
          sortBy: options.sortField === "createdDate",
          sortDesc: !!options.sortDesc,
          sortField: "createdDate",
        },
      },
      {
        displayName: "START DATE",
        sortData: {
          sortable: true,
          sortBy: options.sortField === "startDate",
          sortDesc: !!options.sortDesc,
          sortField: "startDate",
        },
      },
      {
        displayName: "PURCHASE DATE",
        sortData: {
          sortable: true,
          sortBy: options.sortField === "purchaseDate",
          sortDesc: !!options.sortDesc,
          sortField: "purchaseDate",
        },
      },
      {
        displayName: "STATUS",
      },
    ];

    setTableColumns(newTableColumns);

    getPolicyList({
      variables: {
        holderId: customerId,
        sortField: options.sortField,
        sortDesc: options.sortDesc,
        page: options.selectedPage,
        perPage: options.perPage,
        getDocumentCount: options.itemCountIncrement > lastItemCountIncrement,
      },
    });

    if (options.itemCountIncrement > lastItemCountIncrement) {
      setLastItemCountIncrement(options.itemCountIncrement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (data) {
      if (
        data.adminPolicyListView.count !== undefined &&
        data.adminPolicyListView.count !== null
      ) {
        setItemCount(data.adminPolicyListView.count);
      }

      const newTableRows: AdminTableRow[] = [];

      if (data.adminPolicyListView.policies) {
        data.adminPolicyListView.policies.forEach((policy, index) => {
          const cells: React.ReactElement[] = [
            <Text key={`cell-type-${index}`}>{policy.type}</Text>,
            <Text key={`cell-id-${index}`}>{policy.policyId}</Text>,
            <Text key={`cell-webstarId-${index}`}>{policy.webstarId}</Text>,
            <Text key={`cell-created-${index}`}>
              {getDateDisplay(policy.createdDate)}
            </Text>,
            <Text key={`cell-start-${index}`}>
              {getDateDisplay(policy.startDate)}
            </Text>,
            <Text key={`cell-purchase-${index}`}>
              {policy.purchaseDate ? getDateDisplay(policy.purchaseDate) : ""}
            </Text>,
            <Status key={`cell-status-${index}`} status={policy.status} />,
          ];

          newTableRows.push({
            onClickRowValue: policy.policyId,
            cells,
          });
        });

        setTableRows(newTableRows);
      }
    }
  }, [data]);

  return (
    <>
      {loading && <LoadingWrapper loading />}

      {data && (
        <div>
          <AdminTable
            columns={tableColumns}
            rows={tableRows}
            onChangeSortBy={handleChangeSort}
            onClickRow={openPolicyPage}
          />

          <AdminPagination
            itemCount={itemCount}
            perPage={options.perPage}
            pageSelected={options.selectedPage}
            siblingsShown={4}
            onPerPageChange={onChangePerPage}
            onPageSelectedChange={onChangePageSelected}
          />
        </div>
      )}
    </>
  );
};

export default AdminCustomerPoliciesTable;
