import React from "react";
import { format } from "date-fns";
import styles from "./index.module.scss";
import Button from "~/components/Button";
import { H5, TextExtraSmall } from "~/components/Typography";
import { PremiumType, QuoteType } from "../../types";
import TypeImage from "../TypeImage";
import { POLICY_QUOTE_STATUS } from "~/helpers/constants";
import { getTypeOfCoverage } from "~/helpers/getCoverageData";
import { formatCurrency } from "~/helpers/currency";
import { localDateFromIso } from "~/helpers/dates";

type PropsType = {
  quote: QuoteType;
};

export default function QuoteCard({ quote }: PropsType) {
  const {
    id,
    insuredObject,
    expirationDate,
    status,
    typeOfCover,
    vehicle,
    premium,
  } = quote;

  const getDateText = (date: string) => {
    return format(localDateFromIso(date), "MMMM dd, yyyy");
  };

  const getPremiumText = (premium: PremiumType) => {
    const formattedPremium = formatCurrency(Math.round(premium.amount), true);
    switch (premium.schedule) {
      case "annual":
        return `${formattedPremium} one-time payment`;
      default:
        return `${formattedPremium} per month`;
    }
  };

  const getButtonLabel = (status: string) => {
    switch (status) {
      case POLICY_QUOTE_STATUS.quote:
        return "Complete Purchase";
      case POLICY_QUOTE_STATUS.pendingHomeData:
        return "Complete Policy";
      case POLICY_QUOTE_STATUS.pendingSigning:
        return "Complete Purchase";
      case POLICY_QUOTE_STATUS.pendingPayment:
        return "Complete Purchase";
      case POLICY_QUOTE_STATUS.pendingDocuments:
      default:
        return "Complete Purchase";
    }
  };

  return (
    <div className={styles.QuoteCardWrapper}>
      <div className={styles.IconWrapper}>
        <TypeImage
          type={
            vehicle ? (vehicle.type?.toLowerCase() as "car" | "truck") : "home"
          }
          vehicleMake={vehicle ? vehicle.make : undefined}
          variant="quoteCard"
        />
      </div>

      <H5 className={styles.Title}>{insuredObject}</H5>

      <div className={styles.Quote}>
        <TextExtraSmall className={styles.SmallText}>
          Quote Expires
        </TextExtraSmall>

        <TextExtraSmall className={styles.SmallText} fontWeight="bold">
          {getDateText(expirationDate)}
        </TextExtraSmall>
      </div>

      {typeOfCover && premium && (
        <div className={styles.Premium}>
          <TextExtraSmall className={styles.SmallText}>
            {getTypeOfCoverage(typeOfCover)?.title}
          </TextExtraSmall>

          <TextExtraSmall className={styles.SmallText} fontWeight="bold">
            {getPremiumText(premium)}
          </TextExtraSmall>
        </div>
      )}
      <div className={styles.ButtonWrapper}>
        <Button
          className={styles.Button}
          size="small"
          variant="tertiary"
          internalLink={`/resume/${id}`}
        >
          {getButtonLabel(status)}
        </Button>
      </div>
    </div>
  );
}
