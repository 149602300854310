import { FormValue } from "informed";
import React from "react";
import { useHistory } from "react-router";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import { H3, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

import styles from "~/styles/common/textInputPage.module.scss";

type Props = {
  nextPath?: string;
};

export default function IHAppliances({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const homeCtx = useHomeContext();

  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 1,
      currentStep: 1,
      title: "Contents Information",
    });
  });

  useUpdateAssistant({
    text: (
      <TextSmall>
        If yes, we will provide a FREE $5,000 limit for these items. If you
        require a higher limit please add the additional limit above $5,000 to
        the contents list on the following page.
      </TextSmall>
    ),
  });

  const handleNextClick = async (value: FormValue) => {
    setSaving(true);

    await homeCtx.setState({
      islandHeritageAppliances: !!value,
    });

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>Do you own any of the below items in your home?</H3>

          <TextSmall>
            <ul className={styles.ItemList}>
              <li>Refrigerator</li>
              <li>Freezer</li>
              <li>Stove/Cooker</li>
              <li>Microwave</li>
              <li>Washing Machine</li>
              <li>Clothes Dryer</li>
              <li>Combination Washer-Dryer</li>
              <li>Dishwasher</li>
            </ul>
          </TextSmall>

          <RadioBinary handleClick={handleNextClick} field="appliancesField" />
        </>
      )}
    </>
  );
}
