import React from "react";
import { useHistory } from "react-router-dom";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import RadioAndOther from "~/screens/Home/PageShells/RadioAndOther";

type Props = {
  nextPath?: string;
};

export default function CosntructionRoofFrame({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string) => {
    await ihCtx.saveData(
      {
        roofFrame: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <RadioAndOther
      onChange={handleChange}
      text={
        <>
          What is the <u>frame of your roof</u> made with?
        </>
      }
      fieldName="constructionRoofFrame"
      buttonOptions={[
        {
          label: "Metal",
          value: "metal",
        },
        {
          label: "Wood",
          value: "wood",
        },
      ]}
      header={{
        currentStep: 10,
        totalSteps: 24,
        title: "Construction",
      }}
      initialValue={ihCtx.data.roofFrame}
    />
  );
}
