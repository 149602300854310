import React from "react";
import { Form } from "informed";

import { ElementStepType } from "~/helpers/flow";
import { PAYMENT_HOME_RESULT_URL } from "~/routes/index.constant";

import MultiStepForm from "~/components/MultiStepForm";

import ConstructionType from "~/screens/Home/Shared/ConstructionType";
import AdditionalProtection from "~/screens/Home/Shared/AdditionalProtection";
import AccountNote from "~/screens/Account/Note";
import AccountEmail from "~/screens/Account/Email";
import AccountName from "~/screens/Account/Name";
import AccountPhone from "~/screens/Account/PhoneNumber";
import AccountPassword from "~/screens/Account/Password";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import AccountCreated from "~/screens/Account/Created";
import ReplacementValue from "~/screens/Home/Shared/ReplacementValue";
import InsuranceReview from "~/screens/Home/Shared/InsuranceReview";
import AllRiskType from "~/screens/Home/Shared/AllRiskType";
import QuickNote from "~/screens/QuickNote";
import PastClaims from "~/screens/Home/PastClaims";
import ContentsCoverage from "~/screens/Home/Shared/ContentsCoverage";
import AllRiskItems from "~/screens/Home/Shared/AllRiskItems";
import ContentsItems from "~/screens/Home/Shared/ContentsItems";
import RequiredDocuments from "~/screens/Home/RequiredDocuments";
import ContentsValue from "~/screens/Home/Shared/ContentsValue";
import CoverageConfirmation from "~/screens/Home/Shared/CoverageConfirmation";
import DocumentsReview from "~/screens/Identification/DocumentsReview";
import CoverageStart from "~/screens/CoverageStart";
import Payment from "~/screens/Payment/Payment";
import PaymentCheckout from "~/screens/Payment/PaymentCheckout";
import PaymentResult from "~/screens/Payment/PaymentResult";
import ReviewPolicy from "~/screens/ReviewPolicy";
import CoveragePlans from "~/screens/Home/Shared/CoveragePlans";
import Occupation from "~/screens/Occupation";
import VerificationCode from "~/screens/Account/VerificationCode";
import PropertyLocation from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/PropertyLocation";
import PropertyOccupation from "~/screens/Home/Exclusives/IslandHeritage/UnderwritingDetails/PropertyOccupation";
import IHAppliances from "~/screens/Home/Exclusives/IslandHeritage/PropertyValues/Appliances";
import { useHomeContext } from "~/contexts/HomeProvider";
import MaritalStatus from "~/screens/Home/Exclusives/IslandHeritage/Profile/MaritalStatus";
import Honorific from "~/screens/Home/Exclusives/IslandHeritage/Profile/Honorific";
import IHBuildings from "~/screens/Home/Exclusives/IslandHeritage/PropertyValues/Buildings";
import IHOutbuildings from "~/screens/Home/Exclusives/IslandHeritage/PropertyValues/Outbuildings";
import IHSeawall from "~/screens/Home/Exclusives/IslandHeritage/PropertyValues/Seawall";
import AddressInfo from "~/screens/Home/Exclusives/IslandHeritage/AddressInfo";

const COUNTRY_NAME = "Barbados";
const COUNTRY_CODE = "BRB";
const INSURANCE_TYPE = "home";

const BARBADOS_HOME_STEPS: ElementStepType[] = [
  {
    path: "/BRB/home/quick-note",
    headerTitle: "Introduction",
    element: <QuickNote insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/construction-type",
    headerTitle: "Personal Information",
    element: <ConstructionType />,
  },
  {
    path: "/BRB/home/property-location",
    title: "Property Location",
    headerTitle: "Property Location",
    element: <PropertyLocation />,
  },
  {
    path: "/BRB/home/property-occupants",
    headerTitle: "Property Occupants",
    element: <PropertyOccupation />,
  },
  {
    path: "/BRB/home/quote-address-info",
    headerTitle: "Address Info",
    element: <AddressInfo />,
  },
  {
    path: "/BRB/home/replacement-value",
    headerTitle: "Property Information",
    element: <ReplacementValue />,
  },
  {
    path: "/BRB/home/past-claims",
    headerTitle: "Property Information",
    element: <PastClaims />,
  },
  {
    path: "/BRB/home/quote-buildings",
    headerTitle: "Property Information",
    element: <IHBuildings />,
  },
  {
    path: "/BRB/home/quote-outbuildings",
    headerTitle: "Property Information",
    element: <IHOutbuildings />,
  },
  {
    path: "/BRB/home/quote-seawall",
    headerTitle: "Property Information",
    element: <IHSeawall />,
  },
  {
    path: "/BRB/home/contents-coverage",
    headerTitle: "Content Details",
    element: <ContentsCoverage />,
  },
  {
    path: "/BRB/home/ih-appliances",
    headerTitle: "Content Details",
    element: <IHAppliances />,
  },
  {
    path: "/BRB/home/contents-items",
    headerTitle: "Content Details",
    element: <ContentsItems />,
  },
  {
    path: "/BRB/home/additional-protection",
    headerTitle: "Content Details",
    element: <AdditionalProtection />,
  },
  {
    path: "/BRB/home/all-risk-items",
    headerTitle: "Content Details",
    element: <AllRiskItems />,
  },
  {
    path: "/BRB/home/all-risk-type",
    headerTitle: "Content Details",
    element: <AllRiskType countryName={COUNTRY_NAME} />,
  },
  {
    path: "/BRB/home/contents-value",
    headerTitle: "Content Details",
    element: <ContentsValue />,
  },
  {
    path: "/BRB/home/insurance-review",
    headerTitle: "Content Details",
    element: <InsuranceReview countryName={COUNTRY_NAME} />,
  },
  {
    path: "/BRB/home/coverage-start",
    title: "Coverage Start",
    headerTitle: "Coverage Details",
    element: <CoverageStart insuranceType={INSURANCE_TYPE} />,
  },
  /*{
    path: "/BRB/home/existing-motor-policy",
    title: "Existing Motor Policy",
    headerTitle: "Almost there!",
    element: <ExistingMotorPolicy />,
  },*/
  {
    path: "/BRB/home/email",
    title: "What's your email",
    headerTitle: "Almost there!",
    element: (
      <AccountEmail insuranceType={INSURANCE_TYPE} countryCode={COUNTRY_CODE} />
    ),
  },
  {
    path: "/BRB/home/coverage-plan",
    title: "Coverage Plans",
    element: <CoveragePlans countryName={COUNTRY_NAME} />,
  },
  {
    path: "/BRB/home/account",
    title: "Create your account",
    headerTitle: "Create an account",
    element: <AccountNote insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/your-name",
    title: "What shall we call you?",
    headerTitle: "Create an account",
    element: <AccountName insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/title",
    title: "How should we address you?",
    headerTitle: "Create an account",
    element: <Honorific />,
  },
  {
    path: "/BRB/home/marital-status",
    title: "Are you married?",
    headerTitle: "Create an account",
    element: <MaritalStatus />,
  },
  {
    path: "/BRB/home/phone",
    title: "What's your phone number",
    headerTitle: "Create an account",
    element: <AccountPhone insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/password",
    title: "Please create a password",
    headerTitle: "Create an account",
    element: <AccountPassword insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/verification",
    title: "Please enter verification code",
    headerTitle: "Verify account",
    element: <VerificationCode />,
  },
  {
    path: "/BRB/home/account-complete",
    title: "Thank you! Your account has been created.",
    headerTitle: "Create an account",
    hideBackButton: true,
    isAuthorized: true,
    element: <AccountCreated insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/occupation",
    headerTitle: "About you",
    hideBackButton: true,
    isAuthorized: true,
    element: <Occupation insuranceType={INSURANCE_TYPE} />,
  },

  {
    path: "/BRB/home/documents",
    title: "Identity Documents",
    headerTitle: "Identity Documents",
    hideBackButton: true,
    isAuthorized: true,
    element: <RequiredDocuments insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/documents-review",
    title: "Review Documents",
    headerTitle: "Review Documents",
    hideBackButton: true,
    isAuthorized: true,
    element: <DocumentsReview insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/review-policy",
    title: "Review Policy and Signature",
    headerTitle: "Review Proposal and Signature",
    hideBackButton: true,
    isAuthorized: true,
    element: <ReviewPolicy insuranceType={INSURANCE_TYPE} />,
  },
  {
    path: "/BRB/home/payment",
    title: "Payment",
    headerTitle: "Payment",
    hideBackButton: true,
    isAuthorized: true,
    element: (
      <Payment insuranceType={INSURANCE_TYPE} countryName={COUNTRY_NAME} />
    ),
  },
  {
    path: "/BRB/home/checkout",
    headerTitle: "Payment",
    hideBackButton: true,
    isAuthorized: true,
    element: (
      <PaymentCheckout
        paymentResultUrl={PAYMENT_HOME_RESULT_URL}
        insuranceType={INSURANCE_TYPE}
      />
    ),
  },
  {
    path: PAYMENT_HOME_RESULT_URL,
    headerTitle: "Payment",
    hideBackButton: true,
    isAuthorized: true,
    element: <PaymentResult didNotAgreeRedirect={PAYMENT_HOME_RESULT_URL} />,
  },
  {
    path: "/BRB/home/coverage-confirmation",
    title: "Coverage Confirmation",
    headerTitle: "Coverage Confirmation",
    element: <CoverageConfirmation />,
  },
];

const BarbadosHome = () => {
  const homeCtx = useHomeContext();

  // Filter out pages for specific providers
  const stepElements: React.ReactElement[] = React.useMemo(() => {
    const elements: React.ReactElement[] = BARBADOS_HOME_STEPS.filter(
      (step) => {
        if (step.providerExclusive) {
          return homeCtx.provider === step.providerExclusive;
        }

        return true;
      }
    ).map((step) => {
      return step.isAuthorized && step.element ? (
        <AuthorizedRoute passOnProps>{step.element}</AuthorizedRoute>
      ) : (
        step.element
      );
    });

    return elements;
  }, [homeCtx.provider]);

  const parsedSteps: ElementStepType[] = React.useMemo(() => {
    return BARBADOS_HOME_STEPS.filter((step) => {
      if (step.providerExclusive) {
        return homeCtx.provider === step.providerExclusive;
      }

      return true;
    });
  }, [homeCtx.provider]);

  return (
    <Form>
      <MultiStepForm steps={parsedSteps}>{stepElements}</MultiStepForm>
    </Form>
  );
};

export default BarbadosHome;
