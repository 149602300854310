import React from "react";
import { useHistory } from "react-router-dom";
import { useUserContext } from "~/contexts/UserProvider";
import RadioAndOther from "~/screens/Home/PageShells/RadioAndOther";

type Props = {
  nextPath?: string;
};

export default function Honorific({ nextPath }: Props) {
  const history = useHistory();
  const userCtx = useUserContext();

  return (
    <RadioAndOther
      onChange={(value) => {
        userCtx.setState({
          honorific: value,
        });

        if (nextPath) {
          history.push(nextPath);
        }
      }}
      text="What title should we use to refer to you?"
      fieldName="honorific"
      buttonOptions={[
        {
          label: "Mr",
          value: "mr",
        },
        {
          label: "Mrs",
          value: "mrs",
        },
        {
          label: "Miss",
          value: "miss",
        },
        {
          label: "Ms",
          value: "ms",
        },
      ]}
      header={{
        currentStep: 1,
        totalSteps: 1,
        title: "About You",
      }}
      initialValue={userCtx.honorific}
    />
  );
}
