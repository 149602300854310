import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { ToolProps } from "../..";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import hasErrorCode from "~/helpers/hasErrorCode";
import TextInput from "~/components/TextInput";
import Checkbox from "~/components/Checkbox";
import Button from "~/components/Button";
import styles from "./../AdminUpdatePolicyPremium/index.module.scss";

enum Field {
  Premium = "AdminInsertRenewalData-Premium",
  AlmiApproved = "AdminInsertRenewalData-AlmiApproved",
  UnderwriterApproved = "AdminInsertRenewalData-UnderwriterApproved",
  ClientApproved = "AdminInsertRenewalData-ClientApproved",
  VehicleValue = "AdminInsertRenewalData-VehicleValue",
  NotifyClient = "AdminInsertRenewalData-NotifyClient",
  Passcode = "AdminInsertRenewalData-Passcode",
}

const INSERT_RENEWAL_DATA = gql`
  mutation adminInsertRenewalData(
    $policyId: String!
    $premium: Float!
    $almiApproved: Boolean!
    $underwriterApproved: Boolean!
    $clientApproved: Boolean!
    $vehicleValue: Float
    $notifyClient: Boolean!
    $passcode: String!
  ) {
    adminInsertRenewalData(
      policyId: $policyId
      premium: $premium
      almiApproved: $almiApproved
      underwriterApproved: $underwriterApproved
      clientApproved: $clientApproved
      vehicleValue: $vehicleValue
      notifyClient: $notifyClient
      passcode: $passcode
    )
  }
`;

const AdminInsertRenewalData = ({ identifier, onClose }: ToolProps) => {
  const premiumState = useFieldState(Field.Premium);
  const almiApprovedState = useFieldState(Field.AlmiApproved);
  const underwriterApprovedState = useFieldState(Field.UnderwriterApproved);
  const clientApprovedState = useFieldState(Field.ClientApproved);
  const vehicleValueState = useFieldState(Field.VehicleValue);
  const notifyClientState = useFieldState(Field.NotifyClient);
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [insertRenewalData] = useMutation(INSERT_RENEWAL_DATA);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await insertRenewalData({
        variables: {
          policyId: identifier,
          premium: cleanNumberInput(premiumState.value, true),
          almiApproved: !!almiApprovedState.value,
          underwriterApproved: !!underwriterApprovedState.value,
          clientApproved: !!clientApprovedState.value,
          vehicleValue: vehicleValueState.value
            ? cleanNumberInput(vehicleValueState.value, true)
            : undefined,
          notifyClient: !!notifyClientState.value,
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);

      if (response?.data?.adminInsertRenewalData) {
        onClose({
          manuallyClosed: false,
          success: true,
          shouldReload: true,
          message: "Renewal data inserted successfully.",
        });
      } else {
        throw new Error("Failed to update renewal data.");
      }
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <TextInput
        type="text"
        label="Premium"
        placeholder="0.00"
        field={Field.Premium}
        inputMode="decimal"
      />

      <Checkbox field={Field.AlmiApproved} label="Almi Approved" initialValue />

      <Checkbox
        className={styles.SmallMarginTop}
        field={Field.UnderwriterApproved}
        label="Underwriter Approved"
        initialValue
      />

      <Checkbox
        className={styles.SmallMarginTop}
        field={Field.ClientApproved}
        label="Client Approved"
        initialValue
      />

      <TextInput
        type="text"
        label="Vehicle Value"
        placeholder="0.00"
        field={Field.VehicleValue}
        inputMode="decimal"
      />

      <Checkbox
        field={Field.NotifyClient}
        label="Notify Client of Change"
        initialValue
      />

      <TextInput
        type="text"
        label="Passcode"
        placeholder="Passcode"
        field={Field.Passcode}
      />

      <Button
        disabled={!premiumState.value || !passcodeState.value || processing}
        onClick={handleSubmit}
      >
        {processing ? "Processing..." : "Process"}
      </Button>
    </div>
  );
};

export default AdminInsertRenewalData;
