import React from "react";
import { useHistory } from "react-router";
import { useIslandHeritageContext } from "~/contexts/IslandHeritageProvider";
import BooleanAndDetails from "~/screens/Home/PageShells/BooleanAndDetails";

type Props = {
  nextPath?: string;
};

export default function IHDOtherPolicy({ nextPath }: Props) {
  const history = useHistory();
  const ihCtx = useIslandHeritageContext();

  const handleChange = async (value: string | undefined) => {
    await ihCtx.saveData(
      {
        otherPolicy: value,
      },
      nextPath ?? ""
    );

    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <BooleanAndDetails
      onChange={handleChange}
      mainText="Is this property already covered by another policy?"
      detailText="Please provide full details below"
      fieldName="ihdOtherPolicy"
      initialValue={ihCtx.data?.otherPolicy}
      header={{
        currentStep: 21,
        totalSteps: 24,
        title: "Property Details",
      }}
    />
  );
}
